import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import { AppModel } from 'src/app/model/app-model';
import { User } from 'src/app/model/user';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  appModel:AppModel;
  user:User;

  projects:any[];    
 
  isLoading:boolean;

  
  constructor( private apiService:ApiService,private router:Router ) {
    this.user=null;
    this.projects=[];
    this.isLoading=false;
    this.apiService.FetchProjects().pipe(finalize(()=>this.isLoading=true)).subscribe(data=>this.projects=data);
    this.apiService.FetchProjects().pipe(finalize(()=>this.isLoading=true)).subscribe(data=>this.projects=data);
   }

 

  ngOnInit(): void {

    this.isLoading=false;
    localStorage.removeItem("project")
    this.apiService.currentUserSubject.subscribe((user:User) =>{   
      if(user===null)  {
        this.isLoading=true;
        return;
      }
      this.user = user;
      this.isLoading=true;
      
      
    })
    

}

deleteProject(projectId:any){



  this.projects = this.projects.filter(e=> {return e.projectId!=projectId} )
  this.apiService.DeleteProject(projectId)
  .subscribe(data=>{
    this.projects=data;
  });

  

  

}



}
