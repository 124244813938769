import { AfterViewInit, Component, Input, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { ElementServiceService } from '../services/element-service.service';
import { ImageService } from '../services/image.service';
import { fabric } from 'fabric'
import { CanvasService } from '../services/canvas.service';
import { AppDataService } from '../header/app-data.service';
import { AppModel } from '../model/app-model';
import { ConstantService } from '../services/constant.service';
import { ProjectService } from '../services/project.service';
import { Project } from '../model/project';
import { ApiService } from '../services/api.service';
import { GlobalServiceService } from '../services/global-service.service';
import { ProjectValuesService } from '../services/project-values.service';
import { Subscribable, Subscription } from 'rxjs';
@Component({
  selector: 'app-canvas-editor',
  templateUrl: './canvas-editor.component.html',
  styleUrls: ['./canvas-editor.component.scss']
})
export class CanvasEditorComponent implements OnInit, AfterViewInit, OnDestroy {


  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);

  params = {
    textAlign: "center",
    fontFamily: 'Arial',
    fontSize: 30,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fill: '#000',
    backgroundColor: '',
    underline: false,
    overline: false,
    linethrough: false,
    list: false,
    opacity: 1,
    charSpacing: 0,
    padding: 1,
    horizontal: '',
    vertical: '',
    lineHeight: 1,
    flipX: false,
    flipY: false,
    selectable: true,
    strokeWidth: 0,
    stroke: "#000",
    hasControls: true,
    lockMovementX: false,
    lockMovementY: false,
    isLocked: false,
    height: 100,
    radius: 50,
    objectCaching: false,
    angle: 0
  };
  fontFamilyStyles: string[] =
    [

      "Great Vibes",
      "Amatic SC",
      "Caveat",
      "Indie Flower",
      "Sacramento",
      "Satisfy",
      "Kaushan Script",
      "Yellowtail",
      "Calligraffitti",
      "Allura",
      "UnifrakturMaguntia",

      "Rochester",
      "Alex Brush",

      "Bad Script",
      "Grand Hotel",
      "Lobster",
      "Sansita",
      'Arial',
      'Assistant',
      'Arimo',
      'Archivo',
      'Asap',
      'Barlow',
      'Barlow Condensed',
      'Barlow Semi Condensed',
      'Bitter',
      'Cabin',
      'Cairo',
      'Catamaran',
      'Caveat',
      'Cinzel',
      'Comfortaa',
      'Crimson text',
      'DM sans',
      'Dosis',
      'EB Garamond',
      'Exo 2',
      'Fira sans',
      'Fira sans Condensed',
      'Fira Sans Extra Condensed',
      'Fuzzy Bubbles',
      'Heebo',
      'Hind',
      'Hind Madurai',
      'Hind Siliguri',
      'IBM plex sans',
      'Inconsolata',
      'Inter',
      'Josefin sans',
      'Jost',
      'Kanit',
      'Karla',
      'Lato',
      'Lora',
      'Libre Baskerville',
      'Libre Franklin',
      'Manrope',
      'Merriweather',
      'Montserrat',
      'Mukta',
      'Mulish',
      'Nunito',
      'Overpass',
      'Libre Franklin',
      'Poppins',
      'Prompt',
      'Raleway',
      'Rubik Distressed',
      'Rubik Marker Hatch',
      'Work sans',
      'Amatic SC',
      'Kalam',
      'Kristi',
      'Pangolin',
      'Permanent Marker',
      'patrick hand',
      'handlee',
      'Swanky and Moo Moo',
      'Marck Script',
      'Rock Salt'
    ];
  opacityOpen: boolean;
  colorType: string;
  currentPaletteColor: string;
  closeFontStyleList: boolean;
  moreMenu: string;
  isMoreMenu: boolean;
  fontSize: any[] = [];
  canvasColor: string;
  zoom: number;

  active: any;
  type: string;
  appModel: AppModel;

  isCanvasCreated: boolean;

  project: Project;
  activeSubscription: Subscription;



  constructor(
    private elementSvc: ElementServiceService,
    private imageSvc: ImageService,
    private canvasSvc: CanvasService,
    private projectValuesSvc: ProjectValuesService,
    private apiService: ApiService,
    private gloablSvc: GlobalServiceService
  ) {
    console.log("constructorcan")
    this.closeFontStyleList = true;
    this.currentPaletteColor = "#000";
    this.canvasColor = "#fff";
    this.isCanvasCreated = false;
  }
  ngOnDestroy(): void {
    //  this.activeSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {

    //   this.projectSvc.currentProjectSubject.subscribe((project:Project)=>{
    //     if(project==null) return;
    //     this.project= project;
    //     {this.canvasSvc.setUpCanvas();}
    //     this.setZoom();
    //  })

  }


  ngOnInit(): void {

    this.activeSubscription = this.elementSvc.getActiveObject().subscribe((active: any) => {

      this.active = null;
      this.colorType = null;
      this.opacityOpen = false;
      this.currentPaletteColor = "#000"

      if (active != null) {

        if (active.hasOwnProperty("_objects") && active.type != "group") {
          this.params = this.getCommonProperties(active._objects.map(o => o.params));
          this.active = active;
        } else {
          this.active = active;
          this.params = active.params;
        }


        this.type = active.type;
      }

    })





    this.moreMenu = "SPACING";
    this.isMoreMenu = false;
    this.opacityOpen = false;
    this.colorType = null;
    this.zoom = .5;

    for (let i = 1; i < 100; i++) {
      this.fontSize.push(i);
    }

    this.apiService.currentProject.subscribe((project: any) => {
      this.project = JSON.parse(project);
    })

  }

  /**
   *  when we change Element properties from the from parameters
   */
  styleChanged() {
    console.log(this.params)
    this.elementSvc.applyStyle(this.params);
    this.closeFontStyleList = true;
    this.params.horizontal = '';
    this.params.vertical = '';


  }

  colorTypeChange(type: string) {
    this.colorType = type;
    switch (type) {
      case 'fill': this.currentPaletteColor = this.params.fill; break;
      case 'backgroundColor': this.currentPaletteColor = this.params.backgroundColor; break;
      case 'stroke': this.currentPaletteColor = this.params.stroke; break;
      case 'canvasColor': this.currentPaletteColor = this.canvasColor; break;
    }
  }
  closePalette() {
    this.colorType = null;
  }
  handleChange($event: ColorEvent) {
    switch (this.colorType) {
      case 'fill': this.params.fill = $event.color.hex; break;
      case 'backgroundColor': this.params.backgroundColor = $event.color.hex; break;
      case 'stroke': this.params.stroke = $event.color.hex; break;
    }
    this.elementSvc.applyStyle(this.params);


  }

  changeZIndex(type: string) {
    this.elementSvc.changeZIndex(type);
  }

  changeParams(type: string) {
    switch (type) {
      case 'fontWeight': this.params.fontWeight = (this.params.fontWeight === 'bold' ? 'normal' : 'bold'); break;
      case 'fontStyle': this.params.fontStyle = (this.params.fontStyle === 'italic' ? 'normal' : 'italic'); break;
      case 'underline': this.params.underline = !this.params.underline; break;
      case 'textAlignLeft': this.params.textAlign = 'left'; break;
      case 'textAlignCenter': this.params.textAlign = 'center'; break;
      case 'textAlignRight': this.params.textAlign = 'right'; break;
      case 'textAlignJustify': this.params.textAlign = 'justify'; break;
    }
    this.styleChanged();
  }

  transformation(type: string) {
    // this.textBoxSvc.transformation(type)
  }

  setZoom() {

    let zoomLevel = this.zoom;
    let width = this.MENU_TYPES.get(this.projectValuesSvc.project.type).width;
    let height = this.MENU_TYPES.get(this.projectValuesSvc.project.type).height;
    this.gloablSvc.canvas.setZoom(zoomLevel);
    this.gloablSvc.canvas.setDimensions({
      width: width * zoomLevel,
      height: height * zoomLevel
    });
    console.log(this.gloablSvc.canvas.width)
    console.log(this.gloablSvc.canvas.height)
  }

  checkProperty(property: string) {
    return !this.params?.hasOwnProperty(property);
  }
  toggleOpacity() {
    this.opacityOpen = !this.opacityOpen;
  }
  lock() {
    this.elementSvc.lockToggle("image")
  }
  setAsBackGround() {
    this.imageSvc.setBackGround(this.active.getSrc())
  }
  clearTextBackground() {
    this.params.backgroundColor = null;
    this.elementSvc.applyStyle(this.params);
  }

  getCommonProperties(objects): any {

    if (objects.length === 0) {
      return [];
    }

    // Get the list of properties of the first object
    const keys = Object.keys(objects[0]);

    // Filter the list of keys to only include those that are common to all objects
    let commonProperties = keys.filter(key => {
      // Check that the key is present in all objects
      return objects.every(object => {
        return object.hasOwnProperty(key);
      });
    });
    let params = {};
    for (let key in commonProperties) {
      params[commonProperties[key]] = objects[0][commonProperties[key]];
    }

    return params;
  }
}
