import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppModel } from '../model/app-model';
import { Header } from '../model/header';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  private appDTO = new Subject<AppModel>();
  constructor() { }

  getAppDTO(){
    return this.appDTO;
  }
  updateAppDTO(appDTO:AppModel){
    this.appDTO.next(appDTO);
  }
}
