import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from '../Account/profile/profile.component';
import { CanvasComponent } from '../canvas/canvas.component';
import { LoginRegisterComponent } from '../login-register/login-register.component';
import { NewCanvasComponent } from '../new-canvas/new-canvas.component';
import { AuthGuard } from '../services/auth-guard.guard';
 
//import {AuthGuard} from '../../services/auth-guard.guard';

const routes: Routes = [
  {
    path: 'account',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginRegisterComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/:id',
    component: CanvasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/:id/:template?',
    component: CanvasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-project',
    component: NewCanvasComponent,
    canActivate: [AuthGuard]
  },
  
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModuleModule { }
