import { Injectable } from '@angular/core';
import { fabric } from 'fabric'
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { ElementServiceService } from './element-service.service';
import { GlobalServiceService } from './global-service.service';
import { ProjectValuesService } from './project-values.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends ElementServiceService {


  private params = {}
  private imageUploaded = new Subject();
  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);

  constructor(protected globalServce: GlobalServiceService, projectValuesSvc: ProjectValuesService, private apiService: ApiService) {
    super(globalServce, projectValuesSvc)
    this.params = {
      angle: 0,
      padding: 0,
      cornersize: 10,
      strokeWidth: 0,
      stroke: "#000",
      isLocked: false,
      cornerColor: "#00ff00",
      cornerSize: 10,
      cornerStrokeColor: "#00ff00",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "#00ff00",
    }
  }

  uploadLocalImage(e: any) {
    var reader = new FileReader();
    reader.onload = ((event) => {
      var imgObj = new Image();
      imgObj.src = event.target.result + "";
      this.imageUploaded.next(imgObj.src)
    }).bind(this)
    reader.readAsDataURL(e.target.files[0]);
  }

  addSVGFromURL(url: string) {
    let $this = this;
    fabric.loadSVGFromURL(url, function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);
      shape.params = { ...$this.params }
      shape.params.fill = "#2b3140";
      shape.params.isSvgURL = true;

      shape.scaleToHeight(300);
      shape.scaleToWidth(300);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#2b3140";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#2b3140";
      }

      $this.globalServce.canvas.add(shape);
      $this.globalServce.canvas.centerObject(shape);
      $this.globalServce.canvas.setActiveObject(shape);
      $this.globalServce.canvas.renderAll();
    });
  }


  addFrame(url: string) {

    let $this = this;
    let canvas =  this.globalServce.canvas;
    fabric.loadSVGFromURL(url, function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);
      shape.params = { ...$this.params }
      shape.params.fill = "#2b3140";
      shape.params.isSvgURL = true;



      let width = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).width;
      let height = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).height;

      shape.set({ left: 0, top: 0, scaleX: width / shape.width, scaleY: height / shape.height })



      shape.id = 'BG-FRAME';
      let item = $this.globalServce.canvas.getObjects().filter((item) => {
        return item.id === 'BG-FRAME'
      });

      let colorRemovedItem = "gold";
      if (item.length) {
        $this.globalServce.canvas.remove(item[0]);
        // get old color from removed frame 
        item = item[0];
        if (item.hasOwnProperty('_objects')) {
          colorRemovedItem = item._objects[0].fill;
        }
        if (item.hasOwnProperty('path')) {
          colorRemovedItem = item.fill;
        }
        //=================================
      }



      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = colorRemovedItem;
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = colorRemovedItem;
      }

      shape.evented = false;
      shape.selection = false;
      shape.selectable = false;
      canvas.add(shape);
      canvas.centerObject(objects);
      canvas.discardActiveObject();
      canvas.renderAll();
    });


  }
  removeFrame() {
    let id = 'BG-FRAME';
    let canvas =  this.globalServce.canvas;
    let item = canvas.getObjects().filter((item) => {
      return item.id === id
    });
    if (item.length) {
      canvas.remove(item[0]);
    }
    canvas.renderAll();

  }

  addImageFromURL(url: String) {

    let $this = this;
    fabric.Image.fromURL(url, function (img) {
      img.set($this.params);
      img.scaleToWidth(300);
      img.params = { ...$this.params }
      img.sendToBack();
      $this.globalServce.canvas.centerObject(img);
      $this.globalServce.canvas.add(img);
      $this.globalServce.canvas.renderAll();

    }, { crossOrigin: "Anonymous" });

  }

  setBackGround(url: any) {

    let canvas = this.globalServce.canvas;
    fabric.Image.fromURL(url, function (img) {
      let bgImage = img;
      let canvasAspect = canvas.width / canvas.height;
      let imgAspect = bgImage.width / bgImage.height;
      let left, top, scaleFact;
      if (canvasAspect >= imgAspect) {
        var scaleFactor = canvas.width / bgImage.width;
        left = 0;
        top = -((bgImage.height * scaleFactor) - canvas.height) / 2;
      } else {
        var scaleFactor = canvas.height / bgImage.height;
        top = 0;
        left = -((bgImage.width * scaleFactor) - canvas.width) / 2
      }

      canvas.setBackgroundImage(bgImage, canvas.renderAll.bind(canvas), {
        top: top,
        left: left,
        originX: 'left',
        originY: 'top',
        scaleX: scaleFactor * 2.5,
        scaleY: scaleFactor * 2.5,
        crossOrigin: 'anonymous'
      },{ crossOrigin: "Anonymous" });
      canvas.renderAll();
    });
  }


  changeBorderFrameColor(color: string) {
    let canvas = this.globalServce.canvas;
    let frame = this.globalServce.canvas.getObjects().filter((item) => {
      return item.id === 'BG-FRAME'
    });
    if (!frame.length) {
      let map = new Map<any, any>();
      map.set("type", "error")
      map.set("messages", ["The frame cannot be found, Select one to start coloring"])
      this.apiService.getApiErrors().next(map)
    }
    frame = frame[0];
    if (frame.hasOwnProperty('_objects')) {
      for (var i = 0; i < frame._objects.length; i++) {
        frame._objects[i].fill = color;
      }
    }
    if (frame.hasOwnProperty('path')) {
      frame.fill = color;
    }
    frame.set("dirty", true)
    canvas.requestRenderAll();
    canvas.renderAll();

  }


  public getImageUploaded(): Subject<any> {
    return this.imageUploaded;
  }
  public set addToImageUploaded(base64: String) {
    this.imageUploaded.next(base64);
  }




}