import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Response } from '../model/response';
import { User } from '../model/user';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {


  registerForm:FormGroup;
  loginForm:FormGroup;
  isSignin:boolean;
  isLoading:boolean;

  constructor(
    private apiService:ApiService,private router:Router) {
    this.isSignin=false;
    this.isLoading=false;

    
   }

  

  ngOnInit(): void {

    this.apiService.currentUserSubject.subscribe((user:User) =>{   
      if(user!=null)  {
        this.router.navigate(['/account']);
      }      
    })


    this.registerForm = new FormGroup({
      firstName: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
      lastName: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
      email: new FormControl('',[Validators.email,Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
      password: new FormControl('',[Validators.required,Validators.minLength(6),Validators.maxLength(30)]),
    });

    this.loginForm = new FormGroup({
      email: new FormControl('',[Validators.email,Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
      password: new FormControl('',[Validators.required,Validators.minLength(6),Validators.maxLength(30)]),
    });



  }

  registrationForm(){
    this.isSignin=true;
  }

  register(form: FormGroup) {

    if(form.valid){
      this.isLoading=true;
      let user:User={};
      user.firstName = form.controls['firstName'].value;
      user.lastName = form.controls['lastName'].value;
      user.email = form.controls['email'].value;
      user.password = form.controls['password'].value;

      this.apiService.userResgiter(user).
      pipe(finalize(() => {this.isLoading=false})).
      subscribe((response:Response)=>{
        window.location.replace('/new-project');
      })
    }
  }

  login(form: FormGroup) {

    if(form.valid){
      this.isLoading=true;
      let user:User={};
      user.email = form.controls['email'].value;
      user.password = form.controls['password'].value;

      this.apiService.userLogin(user).
      pipe(finalize(() => {this.isLoading=false})).
      subscribe((response:Response)=>{
        this.router.navigate(['/account']);
      })

    }
  }

  signin(){

    this.isSignin=false;
   

  }

  

}
