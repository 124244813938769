import { Component, OnDestroy, OnInit } from '@angular/core';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { ColorEvent } from 'ngx-color';
import { ElementServiceService } from '../services/element-service.service';
import { ImageService } from '../services/image.service';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss']
})
export class CropComponent implements OnInit,OnDestroy {

  isCrop: boolean;
  isBackGround:boolean;
  active:any;
  constructor(
    private elementSvc: ElementServiceService,
    private imageSvc:ImageService
  ) { }
  

  ngOnInit(): void {

    this.isCrop = false;
    this.elementSvc.getSubjectSharedForm().subscribe((type: any) => {
      if (type==="crop") {
        this.isCrop = true;
      }else{
        this.cancelCrop()
      }
    })
    this.elementSvc.getResetAllElements().subscribe((isReset: boolean) => {
      if (isReset) {
        this.isCrop = false;
      }
    })
    this.elementSvc.getActiveObject().subscribe((active: any) => {     
      this.isBackGround=false;
      this.active=active;
    })
  }

  startCrop() {
    this.elementSvc.startCrop();
  }
  applyCrop(){
    this.elementSvc.applyCrop()
  }
  cancelCrop(){
    this.elementSvc.cancelCrop();
  }
  duplicate(){
    this.elementSvc.duplicate()
  }
  delete(){
    this.elementSvc.delete();
  }

  


  ngOnDestroy(): void {
    // this.elementSvc.getSubjectSharedForm().unsubscribe();
    // this.elementSvc.getResetAllElements().unsubscribe();
    // this.elementSvc.getActiveObject().unsubscribe();
  }

}
