import { Injectable } from '@angular/core';
import { ElementServiceService } from './element-service.service';
import { fabric } from 'fabric'
@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {

  public canvas: any;
  protected communBorderStyle: any;
  constructor() {
    this.communBorderStyle = {
      cornerColor: "black",
      cornerSize: 10,
      cornerStrokeColor: "black",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "black",
    }
  }

  public makeAsList() {
    let active = this.canvas.getActiveObject()
    if (active.params.list) {
      active.text = active.text.replaceAll("• ", "");
      active.text = active.text.replaceAll("\n", "\n• ");
      active.text = "• " + active.text;
    } else {
      active.text = active.text.replaceAll("• ", "");
    }
  }

  // setUpCanvas(fabric: any, canvas: any) {
  //   // create a rect object
  //   this.canvas = canvas;
  //   fabric.Object.prototype.transparentCorners = false;
  //   fabric.Object.prototype.cornerColor = 'blue';
  //   fabric.Object.prototype.cornerStyle = 'circle';


  //   this.canvas.on('text:changed', (e) => this.makeAsList())

  //   //Selected object automatically brought to front
  //   this.canvas.preserveObjectStacking = true;
  //   this.canvas.selectionFullyContained = true;
  //   this.canvas.selection = false;


  // }
}
