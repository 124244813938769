<div class="card">
    <div class="card-header">List menu projects</div>
    <div class="card-body">



        <div class="projects row" *ngIf="projects.length">


            <div class="menu column" *ngFor="let p of projects ">
                <p>{{p.name}}</p>
                <img class="thumbnail" [src]="p.thumbnail" />
                <div class="btns">
                    <a [routerLink]="['/dashboard', p.projectId]" class="btn btn-dark update">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADrklEQVR4nO2b24tPURTHNzMuM8Z9eHAtD4RMRimllEgh9xSJopTyxJCUaSYeXP4AlFKKFOaSSG4PriEM8oASEg9uTcidj1azj/Zs+/zmOP3M75yz5/vya37n7P1bn73W2WvvdfYolTIBRcBG4BHwXX9WAZ1V1gV0AY7hlnzfRWUcvp7cOgF0UxmFr7NgdwH9Hd/XZSoScHu+xpoTDmcyEmgDPtODQET4TA4CEeCBQcCozA0C0eDnAZ+BX8DyzAwC0eBnAl+N67sd/aRvEIgGP117PtAr+zFI5SAAnYCDlrG11j2TgQ8W/DgDdhkwI8L6IXnrBGBLG56fBLwPgbchZ6QqEoC5ejILtMO6Ph54FxFetMTxG2GRUKQKKaA78NQwqtHc1QEVwBsLviIOVEgkrGpPXpdRmwxjngE9rXnhiXH9tQVfH/Zsy8QI7HWkSLvd9QJg/zGmFGg2jFnh8FhzDPgKHSnoR2uk1a9soAJ9bGfsVoYsNgy57ypoAFP0rm9EDHh0yhxk9bnNuP5EFUrAEcOQqgj3yyNxtA14iZRA34AFVh+1uVJtuwkolvDTRvwEhkRoU5lneGlf/D85c8GMNQy5E7FNH+CFbnMkD/CFWwzp3B/owD+0KwNGG3+nD14ErDYM2qBiCBjmgF+YeHgRsNMwapGKIWBNKuFFQINh2EQVPwIe6rQ3L03wnYGX2jB5sVGS5/6TCy8CphrGnVY+wYuA/YaBK1X+ompHGuDH6LAXfZLcnoc+y6w5JbHwnYBThpFb89DfUms7ja4sJQteBFQbRj4HeqgYAoYC64EmC1yW1JtlYFTSRMvOL6j6yOf8CG0GAtOAtVL5Bc7pmoFZPQr0GJilkihggV6oBKrOce9wWRkC10JAbb3RZwO6qpTAH3KFKDAY2Af8iAD9VvczJ7HgIfAN9uSkqz7VOiPY+qjDfg+wTqq9MlAqDeJv+EbbW0Bv4LwD/Kye3WNNkkmEP+6AH+CYxR/Iay+VZgGzgS8GlOT97g74exa8lLpKVZrF354/ab99CYHfnsjc/S+SvbgFfzqi50MPPKQZ/ozv8CW+wl+wU5dP8BdlW+or/KUOeE89f9l8ne0b/JUOeE89fxXo5YC/6wP8TaCvr/C3fIa/DfSz7umAxwPPN8mJKl893+QT/GjrBPYdb+BFusaeC77cAV+Y42X/Q7SkuECVXsE7IkBgy72BN97Zm6VsgZ7gBXyOej6ZnPBiDkKN8kW0/GvKDf2yUjY/rU5dZlG/Ac8wW+Wr9KXqAAAAAElFTkSuQmCC">
                        Preview
                    </a>
                    <a (click)="deleteProject(p.projectId)" class="btn btn-red">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVR4nO3aTQ6CMBCG4Vl5KPF6il5LEuNB0Gu8phUTFkyQ8DeB71k2pWSYfptSM9ko4ADcgDf9XkCZnrFogCvDXSwavl85Of4xt/h1xqKhMdf8xYQuBKiIo9pKIfcpOpONXmjt96JCxkEdcexqawFP4DF2PEIhnXOGjrepEI86YtpamTLiUUZMGcmUEY8yYspIpox4lBFTRjJlxKOMmDKSKSMeZcSUkVUzUnX9wxg63qZDbM+uDrHngArpvwxQ2EKAU/POespF072RtZynvjBTtjqzhDoVEfLijVi/D0SjkY/JbZZcAAAAAElFTkSuQmCC">
                        Remove
                    </a>
                </div>

            </div>

       

        </div>
    </div>
</div>
<div class="card user-details" *ngIf="user">
    <div class="card-header">User details</div>
    <div class="card-body">

        <div class="user-photo">
            <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE0klEQVR4nO2aW2hcZRCA/xStVfFJvKCgIlUh3o23xuzMbrwQUZOdORzBWg05M7sp3kq9Pai4Il4fFCrFqg+i1lv6oA8iomL1oQ9FfZBC+1AJokgFFbEqsbZWZTZp8u8mTXc35yTnxP1gYFnOmfln/sv5//nHuTZt2rRpM7/gwPBpeYquQ5Y7kOQZZHkHWT5Hkp1IOgqsPyPLL8j6TyHQs13W6eu78wggJSDdiCy7kPXfhoXkEZdVuq4vHwUsDwLpD005XRMA3e6yCAaSR5JvW3bck8xNAwiiCFj2xeF85qYBUKkfSP+OzfksTYMVtPp4YPkpVuen5Lscq7g0g9VPWiLOT4p9Pl0awcHBZUi6O+kATEyHDpc2oCicuPMTAlS6xKUNYH12vgKArA+5tIGkm2t6ifVlWxSRS1cB693Ish5Y3kOWbcDyTXWrS7IXSf8a/62jQPoFkowA6RM2onrD4ZOR9OnpI0DfdWkDSUennJd9GA6eGIviSmUJkGytDYJsc2kDWX/zeujTOHXnguia2hEg37u0gTacpwLwQMzqO2wfMPUlkDGXNtD/Vhd1IG79wPKSb8OlDfQa1xMOdcatH6h0W5YCcFzs+ovlnswEALFyWNz6rwjLp2QmAC4Brl616uj/dQCM1AYgF5S65zsAZtOlBSQZ8XaBbyVlx3R7e4ERlxbQy/Qmmb9Dis7xtsO7XFoAlj0HGmaZ4KTsmG5vpO1xaQFIv56cm0W5NCk7+aB8mTcFdrq0gKSvTDVM37cTXOxG7FTI+oF/3HZpIUfli5Bkv7dKvxC3DdPp9f5+CKILXZpAP3FhDeThM+LS3VPUM/0AA+mTLnVUKkv8rBCQPp9M7+vmRKZYHFj6yxsFe2GgfO5cdRZo6Hz/lilH2uvSDLJ+5GVutnaVy4e3qqszDJdW84RTvf+hSzuFMDoLSP/wNizrW9UFLBu8YP5ua4HLAjnSm+tyeHc1qwNJ1tZmmWWlyxLg9Z5VegBrudF3sair7Z0kFtR5o/cGOaEun29BePQQ11odSPpY/T2A6XJZA0NZPsONjq0Jr9s94rTnBweXAcmbM74TynKXNfL+vr1eSLdjEF184NmeQM4D1q8O9nyS54vEgGLpWq/XP0GWp/x5PauQvggsn3l7ij6XNYBlZX2SxO4LJsrfDub4bgj0RnsWSd+e/D/Qm1zWyJHe7gVgg18jWF0HSMa8Hh6zEroCR6fO9BUxXS5LrLBSmWpN4OQUeLxZHfaO9xncaDpdmunhodOBS2uA5OP6CjFguadZfUjRvbXTw06Y+qVVjBWK2uUWnEpliTXEGlRd1Wdd1GSolVK7QyyUdh2/rnoAS+AiZkYuD9ceOXHiW9dcyWtUdE1ipbWN6rfaYiB9DUnC7v7oGBcnVxZvORZIb0XWTX4NQDOS4wiatZsvlrAVW0D65/g0LK3p7o9Oav1Ex3I/kG6pS3W1JMDy8Ey7v9lGGrBW5mq3um6QbjFfzKeGA4CkP87Z+LSekV+R5VUrdsQBvcB6xxw1sd/2H5KoDeXxZ+O1bz41HADwd2GLRJoq28HqIrfIAsDyXBMjICosdINjl0DyDQfAjZ/L31jwRscnm5our+0Mw6XIeh+y7oi19n+eZKLNO2xHOZfEbJs2bdq0cYuY/wAS0YBHFIS1LAAAAABJRU5ErkJggg==">
        </div>

        <div class="user-info">
            <label class="label-title">Full name</label>
            <label>{{user.firstName}} {{user.lastName}}</label>

            <label class="label-title">Email</label>
            <label>{{user.email}}</label>

            <label class="label-title">Status</label>
            <label>Active</label>

            <a class="btn btn-dark update">
                <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADM0lEQVR4nO2bOWhUQRiAJ6LxjBqt1MLCYEREPAiKBA9QY2PhEQSNKEIKIdpJTCeKILYKHhBQsRAvLK1EMVHjbWlh4QXiFa9EiZpPfjMPh9n3dt/uBrMzOx+8ZnfmZ76d4821SjkMMBpYA7QArcAOoA6oUD4DTAWOAz3E8wrYDVQq3wBWA92k4zEwXfkC0AT8siQfAkeBQ8AZ4LX1/QtgivJQ/ilQH5NuOLAT6DXSdgLDlEfy94DqHHnqgR9Gni3KE/kHueSNvHuNfHeVB/KPgEl55B9ndIV+p8YCMuV/AgsKiHPDiLFcOTzao2vzgNRsHrEu8o8NykH5XjJ5Bsz2rgWQKd8lAx6wErhq/QifgIYUY8B3J8YA4uUnWmkagOdGmj5gRZaYbU68BYiXn5CQttpqDe+BGTHplljzgM3KEfk7SfJGnkrgipHnicwAje/rrPVCR0nOBClA3sg7Rs8LIloS5F+WZN+nCHkjxlw9DgjvYlaKsjCqVQ7I385X3ojVTjz+ywsyO3RdfnyRMZfqd3zJy2/S8/mIW4MgL0veL0bMN2lniP8Vgjyh5jVl3ew7g3wRhJrH3WZfVc59vqrImEGeUPNuNfuOcm72HUG+CELNE5p9ROjzyoEB72Y5DXgbY05p1w7CNta3kt/GEvTrLQ5pBfOVz/IC8JZk+vQtjApf5auNwn6QGgdOGocREafNoykv5AVgkVHgLuPzWr2lbdKe1BKclDf28SPOqszraPapzC4zjdPyArDfKPg+ZSE1Dpwy0shlhBov5AXgnFH4JpV8VC23NSMueyEv6Dt5EYtV9vO56HjqN9Dsg3wF8NWQmJwj/SVrPHBX3riSHvFR5QDY6o28IFfK7FegnhcsBBplUATOA/eBzzHypTm3TwsD/TiiJ+G+XhLu1rwAjNXb2mnp1/fyrwHH4m5tOQMwArieQ/IEsAdYB8wBRilfAFZZ0q3eSWYDmKZXgLLgaVblCAN/R8v63g8EAgFvAGbq3d/GFM+8FPFm5RFvqJ5lf3ewZJlr7fmnYX2O/X5ZErvAQSnwtgIytmX5AbbjDhekwCOBw3pll+Y5ku1ycwHxhuqRrbyaPxzj0G2aFZ+uAAAAAElFTkSuQmCC">
                update
            </a>

        </div>
    </div>
</div>