import { Injectable } from '@angular/core';
import { fabric } from 'fabric'
import { ElementServiceService } from './element-service.service';
import { GlobalServiceService } from './global-service.service';
import { ProjectValuesService } from './project-values.service';

@Injectable({
  providedIn: 'root'
})
export class TextBoxService extends ElementServiceService {

  private params = {}
  private objects=[];
  constructor(protected globalServce: GlobalServiceService, private elementSvc: ElementServiceService, projectValuesSvc: ProjectValuesService) {
    super(globalServce, projectValuesSvc)
    this.params = {
      textAlign: "center",
      fontFamily: 'Arial',
      fontSize: 30,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      textBackgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      width: 400,
      opacity: 1,
      selectable: true,
      strokeWidth: 0,
      stroke: "#000",
    }

  }
  transformation(type: string) {
    let canvas = this.globalServce.canvas;
    var active = canvas.getActiveObject();


    switch (type) {
      case 'lowercase': active.text = active.text.toLocaleLowerCase(); break;
      case 'uppercase': active.text = active.text.toLocaleUpperCase(); break;
      case 'capitalize': active.text = active.text.charAt(0).toUpperCase() + active.text.slice(1).toLocaleLowerCase(); break;

    }
    canvas.requestRenderAll();
  }
  addHead() {
    this.params = {
      textAlign: "center",
      fontFamily: 'Arial',
      fontSize: 70,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }
    let text = new fabric.Textbox("Text header", this.params);
    text.params = this.params;
    text.set(this.communBorderStyle);
    this.globalServce.canvas.add(text);
    this.globalServce.canvas.centerObject(text);
    this.globalServce.canvas.setActiveObject(text);
    this.addHoverBorder(text);
    this.elementSvc.updateSubjectSharedParams(new Map([["textbox", this.params]]));
    this.elementSvc.updateSubjectSharedForm("textbox");





  }

  addLabel() {

    this.params = {
      textAlign: "center",
      fontFamily: 'Arial',
      fontSize: 50,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      lineHeight: 1,
      padding: 1,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }
    let text = new fabric.Textbox("Label", this.params);
    text.params = this.params;
    text.set(this.communBorderStyle);
    this.globalServce.canvas.add(text);
    this.globalServce.canvas.centerObject(text);
    this.globalServce.canvas.setActiveObject(text);
    this.addHoverBorder(text);
    this.elementSvc.updateSubjectSharedParams(new Map([["textbox", this.params]]));
    this.elementSvc.updateSubjectSharedForm("textbox");
  }

  addParagraph() {

    this.params = {
      textAlign: "justify",
      fontFamily: 'Assistant',
      fontSize: 45,
      fontWeight: 'normal',
      fontStyle: 'italic',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      width: 800,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      lineHeight: 1,
      padding: 1,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }
    let text = new fabric.Textbox("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries", this.params);
    text.params = this.params;
    text.set(this.communBorderStyle);
    this.globalServce.canvas.add(text);
    this.globalServce.canvas.centerObject(text);
    this.globalServce.canvas.setActiveObject(text);
    this.addHoverBorder(text);
    this.elementSvc.updateSubjectSharedParams(new Map([["textbox", this.params]]));
    this.elementSvc.updateSubjectSharedForm("textbox");
  }

  addList() {

    this.params = {
      textAlign: "left",
      fontFamily: 'Assistant',
      fontSize: 35,
      fontWeight: 'normal',
      fontStyle: 'italic',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      width: 100,
      opacity: 1,
      selectable: true,
      list: true,
      charSpacing: 0,
      lineHeight: 1,
      padding: 1,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }
    let text = new fabric.Textbox("List 1\nList 2\nList 3\nList 4", this.params);
    text.params = this.params;
    this.globalServce.canvas.add(text);
    this.globalServce.canvas.centerObject(text);
    this.globalServce.canvas.setActiveObject(text);
    this.addHoverBorder(text);
    this.elementSvc.updateSubjectSharedParams(new Map([["textbox", this.params]]));
    this.elementSvc.updateSubjectSharedForm("textbox");
    this.applyStyle(this.params)
  }


  addDrink1() {

    let params = {
      textAlign: "left",
      fontFamily: 'kalam',
      fontSize: 30,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1.3,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }

    let txts = []
    let $this = this;
    let top = 40;

    
    let drink="";
    drink+=`Orange juice................. 5 $\n`;
    drink+=`Diet coke...................... 2 $\n`;
    drink+=`Limonade...................... 7 $\n`;
    drink+=`Hot Tea........................ 9 $`;
    txts[0] = new fabric.Textbox(drink, { left: 0, top: top+=40, width:250 });
    txts[2] = new fabric.Textbox("Drinks", { left: 120, top: 0 , width:150 });

    fabric.loadSVGFromURL(" ../../assets/images/separator/separator-62.svg", function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);

      shape.scaleToHeight(300);
      shape.scaleToWidth(300);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#000";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#000";
      }
      shape.set({ left: 15, top: 40 });


      txts.forEach(obj => {
        obj.set(params)
        obj.params = params;
        $this.globalServce.canvas.add(obj);
      })

      let shapeParams = {
        angle: 0,
        padding: 0,
        cornersize: 10,
        strokeWidth: 0,
        stroke: "#000",
        isLocked: false,
        cornerColor: "#00ff00",
        cornerSize: 10,
        cornerStrokeColor: "#00ff00",
        cornerStyle: "circle",
        borderDashArray: [3],
        borderColor: "#00ff00",
        fill: '#000',
      }


      shape.set({...shapeParams})
      shape.params = {...shapeParams}
      $this.globalServce.canvas.add(shape);

      txts[1] = shape;

      var selection = new fabric.ActiveSelection(txts, { canvas: $this.globalServce.canvas });
      $this.globalServce.canvas.setActiveObject(selection);
      $this.globalServce.canvas.centerObject(selection);
      $this.globalServce.canvas.requestRenderAll();
      $this.globalServce.canvas.renderAll();


    });

  }

  addAppetizer1 () {

    let params = {
      textAlign: "left",
      fontFamily: 'kalam',
      fontSize: 30,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1.3,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }

    let txts = []
    let $this = this;
    let top = 40;

    

    let food="";
    food+=`Mozzarella sticks............. 5 $\n`;
    food+=`Calamari............................ 2 $\n`;
    food+=`Fried pickles...................... 7 $\n`;
    food+=`Buffalo wings................... 9 $`;
    txts[0] = new fabric.Textbox(food, { left: 0, top: top+=40, width:250 });
    txts[2] = new fabric.Textbox("Appetizers", { left: 100, top: 0 , width:150 });

    fabric.loadSVGFromURL(" ../../assets/images/separator/separator-42.svg", function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);

      shape.scaleToHeight(300);
      shape.scaleToWidth(300);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#000";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#000";
      }
      shape.set({ left: 25, top: 40 });


      txts.forEach(obj => {
        obj.set(params)
        obj.params = params;
        $this.globalServce.canvas.add(obj);
      })

      let shapeParams = {
        angle: 0,
        padding: 0,
        cornersize: 10,
        strokeWidth: 0,
        stroke: "#000",
        isLocked: false,
        cornerColor: "#00ff00",
        cornerSize: 10,
        cornerStrokeColor: "#00ff00",
        cornerStyle: "circle",
        borderDashArray: [3],
        borderColor: "#00ff00",
        fill: '#000',
      }


      shape.set({...shapeParams})
      shape.params = {...shapeParams}
      $this.globalServce.canvas.add(shape);

      txts[1] = shape;

      var selection = new fabric.ActiveSelection(txts, { canvas: $this.globalServce.canvas });
      $this.globalServce.canvas.setActiveObject(selection);
      $this.globalServce.canvas.centerObject(selection);
      $this.globalServce.canvas.requestRenderAll();
      $this.globalServce.canvas.renderAll();


    });

  }

  AddMenu1 () {

    let params = {
      textAlign: "center",
      fontFamily: 'Calligraffitti',
      fontSize: 60,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1.3,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }

    let txts = []
    let $this = this;
    let top = 40;

    

    let menu="Menu";
    txts[0] = new fabric.Textbox(menu, { left: 50, top: top=115, width:250 });


    fabric.loadSVGFromURL(" ../../assets/images/boxes/box-3.svg", function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);

      shape.scaleToHeight(300);
      shape.scaleToWidth(300);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#000";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#000";
      }
      shape.set({ left: 25, top: 40 });


    

      let shapeParams = {
        angle: 0,
        padding: 0,
        cornersize: 10,
        strokeWidth: 0,
        stroke: "#000",
        isLocked: false,
        cornerColor: "#00ff00",
        cornerSize: 10,
        cornerStrokeColor: "#00ff00",
        cornerStyle: "circle",
        borderDashArray: [3],
        borderColor: "#00ff00",
        fill: '#000',
      }


      shape.set({...shapeParams})
      shape.params = {...shapeParams}
      $this.globalServce.canvas.add(shape);

      txts.forEach(obj => {
        obj.set(params)
        obj.params = params;
        $this.globalServce.canvas.add(obj);
      })

      txts[1] = shape;

      var selection = new fabric.ActiveSelection(txts, { canvas: $this.globalServce.canvas });
      $this.globalServce.canvas.setActiveObject(selection);
      $this.globalServce.canvas.centerObject(selection);
      $this.globalServce.canvas.requestRenderAll();
      $this.globalServce.canvas.renderAll();


    });

  }

  AddMenu2 () {

    let params = {
      textAlign: "center",
      fontFamily: 'Calligraffitti',
      fontSize: 50,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1.3,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }

    let txts = []
    let $this = this;
    let top = 40;

    

    let menu="Menu";
    txts[0] = new fabric.Textbox(menu, { left: 45, top: 175, width:250 });


    fabric.loadSVGFromURL(" ../../assets/images/menu/menu-1.svg", function (objects, options) {
      var shape = fabric.util.groupSVGElements(objects, options);

      shape.scaleToHeight(350);
      shape.scaleToWidth(300);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#000";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#000";
      }
      shape.set({ left: 25, top: 40 });


    

      let shapeParams = {
        angle: 0,
        padding: 0,
        cornersize: 10,
        strokeWidth: 0,
        stroke: "#000",
        isLocked: false,
        cornerColor: "#00ff00",
        cornerSize: 10,
        cornerStrokeColor: "#00ff00",
        cornerStyle: "circle",
        borderDashArray: [3],
        borderColor: "#00ff00",
        fill: '#000',
      }


      shape.set({...shapeParams})
      shape.params = {...shapeParams}
      $this.globalServce.canvas.add(shape);

      txts.forEach(obj => {
        obj.set(params)
        obj.params = params;
        $this.globalServce.canvas.add(obj);
      })

      txts[1] = shape;

      var selection = new fabric.ActiveSelection(txts, { canvas: $this.globalServce.canvas });

      $this.globalServce.canvas.setActiveObject(selection);
      $this.globalServce.canvas.centerObject(selection);
      $this.globalServce.canvas.requestRenderAll();
      $this.globalServce.canvas.renderAll();


    });

  }


  addSocialMedia1 () {

    let params = {
      textAlign: "center",
      fontFamily: 'Arimo',
      fontSize: 30,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fill: '#000',
      backgroundColor: '',
      underline: false,
      overline: false,
      linethrough: false,
      opacity: 1,
      selectable: true,
      charSpacing: 0,
      padding: 1,
      lineHeight: 1.3,
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      strokeWidth: 0,
      stroke: "#000",
    }

    var txts = []
    let $this = this;
    let top = 40;

    


    txts[0] = new fabric.Textbox("/iMakeMenu.", { left: 20, top: 18, width:250 });

    fabric.loadSVGFromURL("../../assets/images/social-media/socialmedia-2.svg", function (objects, options)  {
      var shape = fabric.util.groupSVGElements(objects, options);

      shape.scaleToHeight(60);
      shape.scaleToWidth(60);

      if (shape.hasOwnProperty('_objects')) {
        for (var i = 0; i < shape._objects.length; i++) {
          shape._objects[i].fill = "#000";
        }
      }
      if (shape.hasOwnProperty('path')) {
        shape.fill = "#000";
      }
      shape.set({ left: 0, top: 5 });

      let shapeParams = {
        angle: 0,
        padding: 0,
        cornersize: 10,
        strokeWidth: 0,
        stroke: "#000",
        isLocked: false,
        cornerColor: "#00ff00",
        cornerSize: 10,
        cornerStrokeColor: "#00ff00",
        cornerStyle: "circle",
        borderDashArray: [3],
        borderColor: "#00ff00",
        fill: '#000',
      }

      shape.set({...shapeParams})
      shape.params = {...shapeParams}
      txts[1]= shape;

      txts.forEach(obj => {
        obj.set(params)
        obj.params = params;
        $this.globalServce.canvas.add(obj);
      })

      var selection = new fabric.ActiveSelection(txts, { canvas: $this.globalServce.canvas });

      $this.globalServce.canvas.setActiveObject(selection);
      $this.globalServce.canvas.centerObject(selection);
      $this.globalServce.canvas.requestRenderAll();
      $this.globalServce.canvas.renderAll();

      });



  

  }

}
