import { Injectable } from '@angular/core';
import { fabric } from 'fabric'
import { ElementServiceService } from './element-service.service';
import { GlobalServiceService } from './global-service.service';
import { ProjectValuesService } from './project-values.service';

@Injectable({
  providedIn: 'root'
})
export class ShapeService extends ElementServiceService {

  private paramsRect =
    {
      fill: '#2980b9',
      width: 200,
      height: 100,
      opacity: 1,
      selectable: true,
      strokeWidth: 0,
      stroke: "#000",
      horizontal: '',
      vertical: '',
      flipX: false,
      flipY: false,
      isLocked:false,
      cornerColor: "#00ff00",
      cornerSize: 10,
      cornerStrokeColor: "#00ff00",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "#00ff00",
    }
  private paramsCircle =
    {
      fill: '#1abc9c',
      radius: 50,
      objectCaching: false,
      opacity: 1,
      selectable: true,
      strokeWidth: 0,
      stroke: "#000",
      cornerColor: "#00ff00",
      cornerSize: 10,
      cornerStrokeColor: "#00ff00",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "#00ff00",
      editingBorderColor: "#00ff00",
    }
  private paramsTriangle =
    {
      fill: '#c0392b',
      width: 100,
      height: 150,
      objectCaching: false,
      opacity: 1,
      selectable: true,
      strokeWidth: 0,
      stroke: "#000",
      cornerColor: "#00ff00",
      cornerSize: 10,
      cornerStrokeColor: "#00ff00",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "black",
      editingBorderColor: "#00ff00"
    }
  private paramsLine =
    {
      stroke: "#e67e22",
      strokeWidth: 5,
      opacity: 1,
    }

  constructor(protected globalServce: GlobalServiceService, private elementSvc: ElementServiceService,projectValuesSvc:ProjectValuesService) {
    super(globalServce,projectValuesSvc)
  }

  add(type: string) {
    let shape = null;
    switch (type) {
      case 'rect': shape = new fabric.Rect({...this.paramsRect});shape.params = {...this.paramsRect}; break;
      case 'circle': shape = new fabric.Circle({...this.paramsCircle});shape.params = {...this.paramsCircle}; break;
      case 'triangle': shape = new fabric.Triangle({...this.paramsTriangle});shape.params = {...this.paramsTriangle}; break;
      case 'line': shape = new fabric.Line([0, 0, 400, 0],{...this.paramsLine});shape.params = {...this.paramsLine}; break;
      case 'line': shape = new fabric.Line([0, 0, 400, 0],{...this.paramsLine});shape.params = {...this.paramsLine}; break;
      // case 'crop': shape = new fabric.Rect({...this.paramsCrop});shape.params = {...this.paramsCrop}; break;
    }

    shape.set(this.communBorderStyle);
    this.addHoverBorder(shape);
    this.globalServce.canvas.add(shape);
    this.globalServce.canvas.centerObject(shape)
    this.globalServce.canvas.setActiveObject(shape);
    this.elementSvc.updateSubjectSharedParams(new Map([["shape", shape.params]]));
    this.elementSvc.updateSubjectSharedForm("shape");
  }

}


