import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppDataService } from '../header/app-data.service';
import {  AppModel } from '../model/app-model';
import { Header } from '../model/header';
import { Project } from '../model/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {





}
