import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppDataService } from '../header/app-data.service';
import { Project } from '../model/project';
import { ApiService } from '../services/api.service';
import { CanvasService } from '../services/canvas.service';
import { ViewService } from '../services/view.service';
import { fabric } from 'fabric'
import { GlobalServiceService } from '../services/global-service.service';
import { ElementServiceService } from '../services/element-service.service';
import { ProjectValuesService } from '../services/project-values.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {

  /** View HTML */
  loginRegistration: boolean;
  newProject: boolean;
  loadingMenu:boolean;

  canvas: any;
  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);



  public project: Project = { data: null, type: null, name: null, projectId: null };

  private SynchProjectTimer: any;
  private serviceSubscription: Subscription;

  constructor(
    private viewSvc: ViewService,
    private projectValuesSvc: ProjectValuesService,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private canvasSvc: CanvasService,
    private router: Router,
    private elementSvc: ElementServiceService, private gloablSvc: GlobalServiceService
  ) {
    this.newProject = false;
    this.loginRegistration = false;
    this.loadingMenu=true;


  }

  ngAfterViewInit(): void {

    this.setUpCanvas();
    this.checkProjectIdAndFetchDetails();

  }



  ngOnInit(): void {




    this.viewSvc.getView().subscribe((view: string) => {
      this.loginRegistration = false;
      this.newProject = false;
      switch (view) {
        case this.viewSvc.LOGIN_REGITRATION: this.loginRegistration = true; break;
        case this.viewSvc.CREATE_NEW_PROJECT: this.newProject = true; break;
      }
      // ============== after create a new project we resize the canvas ===========
      this.canvasSvc.renderProject.subscribe((startRender: boolean) => {
        if (startRender)
          this.renderCanvasProject();
      })
      //===========================================================================

    })
  }


  CanvasToUrl(canvas, type) {


    try {

      let zoom = 1;
      let quality = 1;
      switch (type) {

        case "THUMBNAIL": zoom = 1; break;
        case "LOW_PNG": zoom = 1; quality = 1; break;
      }
      let width = this.MENU_TYPES.get(this.projectValuesSvc.project.type).width;
      let height = this.MENU_TYPES.get(this.projectValuesSvc.project.type).height;

      width = width * zoom;
      height = height * zoom;
      canvas.setZoom(zoom);
      canvas.setDimensions({
        width: width,
        height: height
      }).renderAll();

      let url = canvas.toDataURL({
        format: 'png',
        left: 0,
        top: 0,
        width: width,
        height: height,
        quality: quality
      });
      return url;

    } catch (error) {
      console.error(error);
    }

  }


  startSynchProject() {

    let $this = this;
    this.SynchProjectTimer = setInterval(function () {
      try {

        if ($this.projectValuesSvc.project.data === JSON.stringify($this.canvas.toJSON(["params", "id","selection","selectable","evented"]))) return;

        if (localStorage.getItem("user") != null) {
          if (localStorage.getItem("token") != null) {
            $this.canvas.clone(function (clonedCanvas) {


              let project = {
                "projectId": $this.projectValuesSvc.project.projectId,
                "data": JSON.stringify($this.canvas.toJSON(["params", "id","selection","selectable","evented"])),
                "lowPng": $this.CanvasToUrl(clonedCanvas, "LOW_PNG"),
                "thumbnail": $this.CanvasToUrl(clonedCanvas, "THUMBNAIL"),
                "type": $this.projectValuesSvc.project.type,
                "name": $this.projectValuesSvc.project.name,
              }

              $this.projectValuesSvc.project.data = JSON.stringify($this.canvas.toJSON(["params", "id","selection","selectable","evented"]));
             
              $this.serviceSubscription = $this.apiService.SyncProject({ ...project }).subscribe((response: any) => {
                $this.projectValuesSvc.project.projectId = response.data.projectId;
                console.log("Synchronized")
              });

            });
          }
        }
      } catch (error) {
        console.error(error);
      }

    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.SynchProjectTimer)
    // this.serviceSubscription.unsubscribe()
  }



  /**=======================================================
   *  check if the project exist by params ID
   * if not return;
   * if exist Load all data and resize canvas
   * then start Synchronization of data based on project id
   * ======================================================
   */
  checkProjectIdAndFetchDetails() {

    let $this = this;
    this.activeRoute.params.subscribe(params => {
      if (params["id"] === undefined) {
        this.router.navigate(['/account']);
      }


      //=============== Fetch Project ==============================
      this.apiService.FetchProjectDetails(params["id"]).subscribe((project: Project) => {


        //==================== Set up project shared object =======
        let width = this.MENU_TYPES.get(project.type).width;
        let height = this.MENU_TYPES.get(project.type).height;
        this.projectValuesSvc.project.projectId = project.projectId;
        this.projectValuesSvc.project.type = project.type;
        this.projectValuesSvc.project.data = project.data;
        this.projectValuesSvc.project.name = project.name;
        this.projectValuesSvc.project.width = width;
        this.projectValuesSvc.project.height = height;

      
        

        //=============== Load project data to Canvas =============
        $this.canvas.loadFromJSON(JSON.parse(project.data), function (callback) {
          $this.canvas.renderAll();
          $this.loadingMenu=false;

          //============= Start Sycnhronization ====================
          $this.startSynchProject();

        })
        //================ update zoom for good visibility ==========
        $this.canvas.setZoom(.5);
        $this.canvas.setDimensions({
          width: width * .5,
          height: height * .5
        }).renderAll()

      })


    })
  }

  setUpCanvas() {

    this.canvas = new fabric.Canvas('myCanvas');


    this.gloablSvc.canvas = this.canvas;

    this.canvas.on({
      'mouse:down': (e) => {
        /**
         *  show params panel when object has been selected
         */


        if (e.target != null) {
          e.target.set(this.elementSvc.communBorderStyle)
          // this.elementSvc.updateSubjectSharedForm(this.isShape(e.target.get("type")));
          this.elementSvc.updateActiveObject(e.target);
          // this.elementSvc.updateSubjectSharedParams(new Map([[this.isShape(e.target.get("type")), { ...e.target.params }]]))

        } else {
          this.elementSvc.updateSubjectSharedForm(null);
          this.elementSvc.updateActiveObject(null);
        }
      }
    });

    // create a rect object
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#00ff00';
    fabric.Object.prototype.cornerStyle = 'circle';


    // this.canvas.on('text:changed', (e) => this.makeAsList())

    //Selected object automatically brought to front
    this.canvas.preserveObjectStacking = true;
    this.canvas.selectionFullyContained = true;
    this.canvas.selection = true;
    this.initAligningGuidelines(this.canvas)
  }


  renderCanvasProject() {

    if (this.projectValuesSvc.isEmpty()) return;
    let zoomLevel = .5;
    let width = this.MENU_TYPES.get(this.projectValuesSvc.project.type).width;
    let height = this.MENU_TYPES.get(this.projectValuesSvc.project.type).height;
    this.canvas.setZoom(zoomLevel);
    this.canvas.setDimensions({
      width: width * zoomLevel,
      height: height * zoomLevel
    });

  }

  initAligningGuidelines(canvas) {

    var ctx = canvas.getSelectionContext(),
      aligningLineOffset = 5,
      aligningLineMargin = 4,
      aligningLineWidth = 1,
      aligningLineColor = 'rgb(0,255,0)',
      viewportTransform,
      zoom = 1;

    function drawVerticalLine(coords) {
      drawLine(
        coords.x + 0.5,
        coords.y1 > coords.y2 ? coords.y2 : coords.y1,
        coords.x + 0.5,
        coords.y2 > coords.y1 ? coords.y2 : coords.y1);
    }

    function drawHorizontalLine(coords) {
      drawLine(
        coords.x1 > coords.x2 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        coords.x2 > coords.x1 ? coords.x2 : coords.x1,
        coords.y + 0.5);
    }

    function drawLine(x1, y1, x2, y2) {
      ctx.save();
      ctx.lineWidth = aligningLineWidth;
      ctx.strokeStyle = aligningLineColor;
      ctx.beginPath();
      ctx.moveTo(((x1 * zoom + viewportTransform[4])), ((y1 * zoom + viewportTransform[5])));
      ctx.lineTo(((x2 * zoom + viewportTransform[4])), ((y2 * zoom + viewportTransform[5])));
      ctx.stroke();
      ctx.restore();
    }

    function isInRange(value1, value2) {
      value1 = Math.round(value1);
      value2 = Math.round(value2);
      for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
        if (i === value2) {
          return true;
        }
      }
      return false;
    }

    var verticalLines = [],
      horizontalLines = [];

    canvas.on('mouse:down', function () {
      viewportTransform = canvas.viewportTransform;
      zoom = canvas.getZoom();
    });
    canvas.on('object:added', function (e) {
      let canvasObjects = canvas.getObjects();
      
      canvasObjects.forEach((object:any) => {
        if(object.type=="textbox"){
          object.bringToFront();
        }
      });
      canvas.requestRenderAll();
      canvas.renderAll();
      
    })

    canvas.on('object:moving', function (e) {

      var activeObject = e.target,
        canvasObjects = canvas.getObjects(),
        activeObjectCenter = activeObject.getCenterPoint(),
        activeObjectLeft = activeObjectCenter.x,
        activeObjectTop = activeObjectCenter.y,
        activeObjectBoundingRect = activeObject.getBoundingRect(),
        activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
        activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
        horizontalInTheRange = false,
        verticalInTheRange = false,
        transform = canvas._currentTransform;

      if (!transform) return;

      // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
      // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

      for (var i = canvasObjects.length; i--;) {

        if (canvasObjects[i] === activeObject) continue;

        var objectCenter = canvasObjects[i].getCenterPoint(),
          objectLeft = objectCenter.x,
          objectTop = objectCenter.y,
          objectBoundingRect = canvasObjects[i].getBoundingRect(),
          objectHeight = objectBoundingRect.height / viewportTransform[3],
          objectWidth = objectBoundingRect.width / viewportTransform[0];

        // snap by the horizontal center line
        if (isInRange(objectLeft, activeObjectLeft)) {
          verticalInTheRange = true;
          verticalLines.push({
            x: objectLeft,
            y1: (objectTop < activeObjectTop)
              ? (objectTop - objectHeight / 2 - aligningLineOffset)
              : (objectTop + objectHeight / 2 + aligningLineOffset),
            y2: (activeObjectTop > objectTop)
              ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
              : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
        }

        // snap by the left edge
        if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
          verticalInTheRange = true;
          verticalLines.push({
            x: objectLeft - objectWidth / 2,
            y1: (objectTop < activeObjectTop)
              ? (objectTop - objectHeight / 2 - aligningLineOffset)
              : (objectTop + objectHeight / 2 + aligningLineOffset),
            y2: (activeObjectTop > objectTop)
              ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
              : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
        }

        // snap by the right edge
        if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
          verticalInTheRange = true;
          verticalLines.push({
            x: objectLeft + objectWidth / 2,
            y1: (objectTop < activeObjectTop)
              ? (objectTop - objectHeight / 2 - aligningLineOffset)
              : (objectTop + objectHeight / 2 + aligningLineOffset),
            y2: (activeObjectTop > objectTop)
              ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
              : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
        }

        // snap by the vertical center line
        if (isInRange(objectTop, activeObjectTop)) {
          horizontalInTheRange = true;
          horizontalLines.push({
            y: objectTop,
            x1: (objectLeft < activeObjectLeft)
              ? (objectLeft - objectWidth / 2 - aligningLineOffset)
              : (objectLeft + objectWidth / 2 + aligningLineOffset),
            x2: (activeObjectLeft > objectLeft)
              ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
              : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
        }

        // snap by the top edge
        if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
          horizontalInTheRange = true;
          horizontalLines.push({
            y: objectTop - objectHeight / 2,
            x1: (objectLeft < activeObjectLeft)
              ? (objectLeft - objectWidth / 2 - aligningLineOffset)
              : (objectLeft + objectWidth / 2 + aligningLineOffset),
            x2: (activeObjectLeft > objectLeft)
              ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
              : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
        }

        // snap by the bottom edge
        if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
          horizontalInTheRange = true;
          horizontalLines.push({
            y: objectTop + objectHeight / 2,
            x1: (objectLeft < activeObjectLeft)
              ? (objectLeft - objectWidth / 2 - aligningLineOffset)
              : (objectLeft + objectWidth / 2 + aligningLineOffset),
            x2: (activeObjectLeft > objectLeft)
              ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
              : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
          });
          activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
        }
      }

      if (!horizontalInTheRange) {
        horizontalLines.length = 0;
      }

      if (!verticalInTheRange) {
        verticalLines.length = 0;
      }
    });

    canvas.on('before:render', function () {
      canvas.clearContext(canvas.contextTop);
    });

    canvas.on('after:render', function () {
      for (var i = verticalLines.length; i--;) {
        drawVerticalLine(verticalLines[i]);
      }
      for (var i = horizontalLines.length; i--;) {
        drawHorizontalLine(horizontalLines[i]);
      }

      verticalLines.length = horizontalLines.length = 0;
    });

    canvas.on('mouse:up', function () {
      verticalLines.length = horizontalLines.length = 0;
      canvas.renderAll();
    });



    const STEP = 1;

    var Direction = {
      LEFT: 0,
      UP: 1,
      RIGHT: 2,
      DOWN: 3
    };

    fabric.util.addListener(document.body, 'keydown', function (options) {
      if (options.repeat) {
        return;
      }
      var key = options.which || options.keyCode; // key detection
      if (key === 37) { // handle Left key
        moveSelected(Direction.LEFT);
      } else if (key === 38) { // handle Up key
        moveSelected(Direction.UP);
      } else if (key === 39) { // handle Right key
        moveSelected(Direction.RIGHT);
      } else if (key === 40) { // handle Down key
        moveSelected(Direction.DOWN);
      }
    });

    function moveSelected(direction) {

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        switch (direction) {
          case Direction.LEFT:
            activeObject.left -= STEP;
            break;
          case Direction.UP:
            activeObject.top -= STEP;
            break;
          case Direction.RIGHT:
            activeObject.left += STEP;
            break;
          case Direction.DOWN:
            activeObject.top += STEP;
            break;
        }
        activeObject.setCoords();
        canvas.renderAll();

      }
    }


    // Disable arrow key scrolling in users browser
    window.addEventListener("keydown", function (e) {
      // space and arrow keys
      if ([ 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault();
      }
    }, false);



  }

  reload(){
    location.reload();
  }

}
