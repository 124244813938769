<div class="container-fluid" >
    <div class="container">
   
      <app-object-left-bar></app-object-left-bar>
    
      <app-crop ></app-crop>
      <app-canvas-editor></app-canvas-editor>

      <div [hidden]="!loadingMenu" class="loading">
        <img class="loading-logo" src="../../assets/images/logo.png"  />
        <p>Please wait, Loading Menu...</p>
        
        <button (click) ="reload()" class="btn btn-white">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADq0lEQVR4nO2Zz2scZRjH36SptrfSImrbc1oCRTQo27r7PKa59GIzz7NMioguu8+zW1By8KT2slBoemyx2v4DFZHSQ1uw6EHEHMSrbU9KwF9gNHqLJkYbeWbfSYYQdDc7M+3gfmAIS2bfd77zPvu87/N9nBswYMCAHhkCaj4LpGeQ5EMkvYcsC8CyjCR/IstvQHIXWa8jy9tIWnLt9rDLA2T9Eli++Ld7jp1s7AfSWWT9EVnXermA5XsgPY+kB7MWsmbXVv8rhbIXWd5F0pX1hyP5FkgvAemrE1R/6viL8jjWarvGW62dk8Er+6DaeBqr+hKyvAcs32x8T1eA9LLdk6sQqOo0sC76h//bh1LJwquX8SFoHEWWq8Cy2lkhXaywnHJZC7E3a28z8SY/RW4d7neecqCjwHp7PeRILyO2R1wWQixEkPSmj+1lYG31ugL/RYVVkGXJz3GrFL6xO5WB1988tkeQ5aPOZ1nwYZQJE4GOR3N4MamsTCK7vOP//gJTrSMuY8qBjsZiLJT7HnBTqlyuBPKcy4kJWxmS36O5qzqdmpAK6eupPWW38wfa9EnlZ0v32x+o+41tLlUFCZD0Y5/JLrnt0vUuTfK5y4iJsHEISP+y0D4enj7gigySvu9XZdYVmUq1eawjRH4Iw3CHKzBDSDIfJZ0cM2cmAMsVv6+85YpMhfRlnyGvuSJTodYzfkW+ckWmHNYfi49JqVaGeXPixMyj8VGp6y/FVd9YGD7iHiKAZa6nzRdJfzUhmZWeeQEdt2PNamxXZDCybHTNjAJXZMC8qbSKmgcJkpb8Ie3rtOvyfGm3h5H1u0hM0DjqigyYA9gJr6uuyCDpwcgBZFk1MyDTvaHLAs5texIzyjoO4O1Unz5vIZPm13pr1MwzlzNIOpOKEMO8WP9bWTKLxuUEmC+cNMj7FZIMMTPNsvy9xJiTHx+TgOVCakLMtozsSy8my5V5geV587H8XDesRk9NiGGG8roYcwADbbr0O16vJcLpenz6TlVINGDHzN5oK7B+Yr5Tv+OWw/oYsHzmx7yPJBeT5nXqQmLMi42X38wz853MsunxODOEQascHVCtWeTDFkhp842ZCTHMi43abNbs3HAc583tMKPAamxL3xYe1lupUOvJqPVGUrfkYR5V0iCPVmGqtmeruTIVkmyGIsu5+GzW02U9R9azGNaeSLUy7It2e9jMM/OdzLIxtyNKoZ1G5x/A+hOw3gGWD4D0TVux/iYcMGDA/5J/ACtlf+xzOOYTAAAAAElFTkSuQmCC">
          Force refresh
        </button>
        <br>
        <img class="loading-img" src="../../assets/loading.gif" />
      </div>

      <div [hidden]="loadingMenu"  class="canvas-holder">   

        <canvas   id="myCanvas" width="816" height="1058" style="border:1px solid #000000;">
        </canvas>
      </div>
      
    </div>
    </div>


    <app-login-register  *ngIf="loginRegistration" ></app-login-register>
    <app-new-project  *ngIf="newProject"></app-new-project>