import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorSketchModule } from 'ngx-color/sketch';

import { ObjectLeftBarComponent } from './object-left-bar/object-left-bar.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropComponent } from './crop/crop.component';
import { TooltipDirective } from './Directive/TooltipDirective';
import { CanvasEditorComponent } from './canvas-editor/canvas-editor.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { AlertComponent } from './alert/alert.component';
import { SafePipe } from './pipe/SafePipe';
import { ProfileComponent } from './Account/profile/profile.component';
import { CanvasComponent } from './canvas/canvas.component';
import { RoutingModuleModule } from './routing-module/routing-module.module';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { NewCanvasComponent } from './new-canvas/new-canvas.component';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { TestComponent } from './test/test.component';




@NgModule({
  declarations: [
    AppComponent,
    ObjectLeftBarComponent,
    HeaderComponent,
    CropComponent,
    TooltipDirective,
    CanvasEditorComponent,
    LoginRegisterComponent,
    NewProjectComponent,
    AlertComponent,
    SafePipe,
    ProfileComponent,
    CanvasComponent,
    NewCanvasComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    CommonModule,
    BrowserAnimationsModule,
    MatGridListModule,
    FormsModule,
    ColorSketchModule,
    ColorTwitterModule,
    MatCardModule,
    MatButtonToggleModule,
    HttpClientModule,
    ImageCropperModule,
    ReactiveFormsModule,
    RoutingModuleModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
