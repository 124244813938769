import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {config, Observable} from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.apiService.currentUserValue;
    const currentJwtToken = this.apiService.currentJwtTokenValue;

    const isApiUrl = request.url.startsWith('https://imakemenu-e0296cc29af3.herokuapp.com/api/');

    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          'X-Auth' : currentJwtToken
        },
        withCredentials: false
      });
    } else {
      request = request.clone({
        withCredentials: false
      });
    }

    return next.handle(request);
  }
}
