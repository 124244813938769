import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [       // metadata array
      trigger('toggleOpen', [     // trigger block
      state('true', style({      // final CSS following animation
        right: '10px'
      })),
      state('false', style({
        right: '-400px'
      })),
      transition('true => false', animate('400ms linear')),  // animation timing
      transition('false => true', animate('200ms linear'))
    ])
  ]        // end
})
export class AlertComponent implements OnInit {

  isOpen:boolean;
  messages:string[];
  typeError:string;
  constructor(private apiSvc:ApiService) {
    this.isOpen=false;
    this.messages=[];
  }

  ngOnInit(): void {
    this.apiSvc.getApiErrors().subscribe((map:Map<any,any>)=>{
      this.typeError=map.get("type");
      this.messages=map.get("messages");
      this.isOpen=true;
      setTimeout(()=>{this.isOpen=false},10000)
    })
  }

  closeAlert(){
    this.isOpen=false;
  }

}
