import { Injectable } from '@angular/core';
import { Project } from '../model/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectValuesService {

  public project:Project;

  constructor() {
    this.project = { data: null, type: null, name: null, projectId: null };
   }

   public isEmpty(){
    return (this.project.type==null || this.project.name==null)
   }

   public clearProject(){
    this.project= { data: null, type: null, name: null, projectId: null };
   }
}
