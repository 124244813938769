import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { IconifyService } from '../services/iconify.service';
import { PexelPhotoSearchService } from '../services/pexelphotosearch.service';
import { ShapeService } from '../services/shape.service';
import { TextBoxService } from '../services/TextBoxService';
import { ImageService } from '../services/image.service';
import { ViewService } from '../services/view.service';
import { ProjectService } from '../services/project.service';
import { Project } from '../model/project';
import { ProjectValuesService } from '../services/project-values.service';
import { ColorEvent } from 'ngx-color';
import { ElementServiceService } from '../services/element-service.service';
import { CanvasService } from '../services/canvas.service';
import { CanvasComponent } from '../canvas/canvas.component';
import { GlobalServiceService } from '../services/global-service.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-object-left-bar',
  templateUrl: './object-left-bar.component.html',
  styleUrls: ['./object-left-bar.component.scss']
})
export class ObjectLeftBarComponent implements OnInit,OnDestroy {



  perPage: any;
  photos = [];
  titles = [];
  photosUploaded = [];
  photosBackground = [];
  photoFood = [];
  wallpaperSize = 30;
  icons: any = [];
  templatesPng: any = [];

  barsPng: any = [];
  barsSvg: any[];
  elementsSvg: any[] = [];
  selectedSideBar: string;
  project: Project;

  socialMedia = [];
  boxes = [];
  corners = [];
  food = [];
  separators = [];
  framesSvg = [];

  elementType: string;


  currentPaletteColor: string[];

  downloadPngFormat:SafeUrl;
  downloadSvgFormat:SafeUrl;
  downloadPdfFormat:SafeUrl;
  
  isPreparing:any;
  jsPDF:jsPDF;

  templates:any[];
  

  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);


  constructor(
    protected globalServce: GlobalServiceService,
    private textBoxService: TextBoxService,
    private shapeService: ShapeService,
    private imageService: ImageService,
    private pexelPhotoSearchService: PexelPhotoSearchService,
    private elementSvc: ElementServiceService,
    public projectValuesSvc: ProjectValuesService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private router:Router
  ) {
    this.apiService.FetchAllTemplates().subscribe((data)=> this.templates=data)
    this.jsPDF = new jsPDF('p', 'pt', 'legal');
    this.isPreparing="BEGIN";
    this.perPage = 30;
    this.currentPaletteColor = ['#000000', '#cccccc', '#3498db', '#9b59b6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d',
      '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#63cdda', '#778beb', '#e77f67']
  }

  
  ngOnInit(): void {
    this.selectedSideBar = 'TEMPLATE';
    this.search("burger");
    this.elementType = "TITLE";

    this.imageService.getImageUploaded().subscribe((base64: any) => {
      this.photosUploaded.push(base64);
    });


    this.photosBackground = [];
    this.titles = [];
    this.socialMedia = [];
    this.boxes = [];
    this.corners = [];
    this.food = [];
    this.separators = [];
    this.framesSvg = [];
    this.downloadPngFormat=false;

    for (let i = 1; i <= 2; i++) { this.templatesPng.push("../../assets/images/templates/template-" + i + ".png"); }
    for (let i = 1; i <= 30 / 2; i++) { this.photosBackground.push("../../assets/images/background/background-" + i + ".jpg"); }
    for (let i = 1; i <= 15; i++) { this.titles.push("../../assets/images/menu/menu-" + i + ".svg"); }
    for (let i = 1; i <= 50; i++) { this.socialMedia.push("../../assets/images/social-media/socialmedia-" + i + ".svg"); }
    for (let i = 1; i <= 46; i++) { this.boxes.push("../../assets/images/boxes/box-" + i + ".svg"); }
    for (let i = 1; i <= 27; i++) { this.corners.push("../../assets/images/corner/corner-" + i + ".svg"); }
    for (let i = 1; i <= 424; i++) { this.separators.push("../../assets/images/separator/separator-" + i + ".svg"); }
    for (let i = 1; i <= 100; i++) { this.food.push("../../assets/images/food/food-" + i + ".svg"); }
    for (let i = 1; i <= 76; i++) { this.framesSvg.push("../../assets/images/frames/frame-" + i + ".svg"); }


      
     
}


  addObject(type: string, e: any) {
    switch (type) {
      case 'head': this.textBoxService.addHead(); break;
      case 'label': this.textBoxService.addLabel(); break;
      case 'paragraph': this.textBoxService.addParagraph(); break;
      case 'list': this.textBoxService.addList(); break;
      /**
       *  add Shapes
       */
      case 'rect': this.shapeService.add(type); break;
      case 'circle': this.shapeService.add(type); break;
      case 'triangle': this.shapeService.add(type); break;
      case 'line': this.shapeService.add(type); break;
      /**
       * add Images
       */
      case 'image': this.imageService.updateSubjectSharedForm(e); break;
      case 'imageFromURL': this.imageService.addImageFromURL(e); break;
      case 'SVG': this.imageService.addSVGFromURL(e); break;
      case 'FRAME': this.imageService.addFrame(e); break;
      case 'background': this.imageService.setBackGround(e); break;
      /**
       * combinations
       */

       case 'drink-1': this.textBoxService.addDrink1(); break;
       case 'appetizer-1': this.textBoxService.addAppetizer1(); break;
       case 'menu-1': this.textBoxService.AddMenu1(); break;
       case 'menu-2': this.textBoxService.AddMenu2(); break;
       case 'instagram-1': this.textBoxService.addSocialMedia1(); break;
    }

  }

  onKeySearch(event: any) {
    if (event.keyCode == 13) {
      this.search(event.target.value);
    }
  }


  search(searchData: string) {
    this.photos = [];
    if (!searchData.trim().length) searchData = 'photo';
    this.pexelPhotoSearchService.getdata(searchData, this.perPage).subscribe((response: any) => {

      this.photos = response.photos;

    }, (error) => {
      console.log(error);
    })
  }

  upload(event: any) {
    this.imageService.uploadLocalImage(event);
  }
  handleFrameColorChange($event: ColorEvent) {
    this.imageService.changeBorderFrameColor($event.color.hex);
  }
  handleChangeBackground($event: ColorEvent) {
    this.imageService.setBackGround(null);
    this.elementSvc.setCanvasBackGround($event.color.hex);
  }

  clearBackGround() {
    this.imageService.setBackGround(null);
    this.elementSvc.setCanvasBackGround(null);
  }
  removeFrame() {
    this.imageService.removeFrame();
  }


  prepareDownload(){

    this.isPreparing="PROCESSING";
    let $this=this;
    this.globalServce.canvas.clone(function (canvas) {

    try {

      let zoom = 4;
      let quality = 1;
      let width = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).width;
      let height = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).height;

      width = width * zoom;
      height = height * zoom;
      console.log("width : "+ width +" height : " + height)
      canvas.setZoom(zoom);
      canvas.setDimensions({
        width: width,
        height: height
      }).renderAll();

     let base64 = canvas.toDataURL({
        format: 'png',
        left: 0,
        top: 0,
        width: width,
        height: height,
        quality: quality
      });

      var blob = new Blob([canvas.toSVG()], {type: 'image/svg+xml;charset=utf-8'});
      $this.downloadSvgFormat = $this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));

      $this.downloadPngFormat = base64;

      $this.jsPDF = new jsPDF('p', 'pt', $this.projectValuesSvc.project.type+"");
      $this.jsPDF.addImage( base64 ,'PNG', 0, 0, (width*0.75)/4, (height*0.75)/4);

      setTimeout(()=>{
       
        $this.downloadPdfFormat =  $this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL($this.jsPDF.output('blob')));
        $this.isPreparing="COMPLETED";
      },5000)
     
    } catch (error) {
      console.error(error);
    }
  })


  }

  useAsTemplate(templateId:any,projectId:string){
    this.apiService.useAsTemplate(templateId,projectId).subscribe(response=>{
      window.location.replace("/dashboard/"+response.projectId);
    })
  }



  ngOnDestroy(): void {
    
  }




}
