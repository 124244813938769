
<div [ngClass]="{'displayNoneImportant' : !params?.isLocked}"  class="canvas-editor">
    <div [tooltip]="'Unlock'" class="btn-editor btn-locked" (click)="lock()"></div>
</div>
<div [ngClass]="{'displayNoneImportant' : !active || params?.isLocked}"  class="canvas-editor">
    <input [hidden]="checkProperty('fontFamily')" [tooltip]="'Font style'" [value]="params?.fontFamily" class="select font-family" (click)="closeFontStyleList=false" [(ngModel)]="params.fontFamily" />
        
    <div [hidden]="closeFontStyleList" class="font-family-wrapper">
        <img class="close" (click)="closeFontStyleList=true" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAFHklEQVRoge2aX2xTdRTHP+cWNlYIT0AUNMEJA2OIJoKOxGBbkNGRPs7o/gRD2AQXfTOy+LLEBBIT30BUCBAom1GfNrcyMtqqUaNETSTRiYvgCyQMXxAK29p7fGhv6dq79t62Gzzwefr9zu93z/me9vfv/lp4yP1FquksFOry3p5KrDdFGgwxl5uIF8BAE6YaE4bqpcU13rHBwU8T1YpZcQKB4K4nlVQr0AQ8Dyws8cg08KOojBieVN/oUN/flcQvO4Gtze0vp0zpEVFfBX4UiKlpHIyPnBotx4HrwP6mXU9jpA4DL5UTsAhxA+0+Hznzu5uHPE479vb2GuJ9fD9ifgbUu5ZXmtWKdNWv2TB9efzid04fcvQN+HwtS6ir/VwgWL4+FwhDmph8NR7/4lbpriXwhV5bJklPBNhYFXEOUbjAglRzfLD/RrF+RrHGUKjLS9IYYJ7FAwhskqQn4vO1LCnWb9YEent7jf+SiS8F2Vx9eY7ZaNQt6qfISJl1EkvdYz0Cb8yJLHc0PLH2mcSV8d9sJ7ZtZpml8ldKb0rzxVTKkGe/GTr9R36D/RAyUh/x4IgHqPGYesiuoSCBQLBtO7BlziW5JxAIdgTyjQUJKPLu/Ohxj4rZk2+bMQcyB7O/8u0OSALXgZUO+18FVgALXMZR1QX18bMnr1iGGd+AkmzDvfhpMXkhFgmvEpF9gFmkr6nK3lgkvArDbCSduBtEJNmaa8gbQrLdpUOAiehI+BeA6PDpj0E7sU/CBO2Mnw1/AhAb6vsZKLrLzkJTbiWbQCjU5SV9nnfLSt+O9ux+EYucOW6ThAnamW5LE2ju2As8Uka8xs0tLXVWJZvA7anEespcOkU4Egi277PqsciZ4yLsSQtHRaQ7V7w/2LZbVQ+XEwuoWXSztsGqZBMwRRrs+ztCFA75g227LUN0OHwi/U3onvTQSpPuI0cpcQ4riod1VjG7Ckh6VagEA+RYINheG42Ej4A1nO5RFfGAIstzgmYQLXrqc4goHM4dThbVEg8gsNQqV+zMPoJooanQVg3uJaBS8u3HASoib+aOeYvocPhEzsSuLAjctMpGjvF6hX5NuwlrP7ErS0LQCaucTcBQvVSBT9ulMj3m5ViRJbY8UvxpFbMJLK7xjgFT5fhTZV+RpdJ2iRWR7rLEw+TdpZPZD3vGuccfbP8WeNGlw6uxSHjVPR+zrjYFu7E/2H4N97vx17FI2GdV8oOcc+kMYIV/Z+tzYB0PZl0qDZCjmSMEvuaOjcAy19FERmZUcyvbdrbWp0xjPN/ugCTpg5nTT/MasJxqH6czF60xl07JCHEzFB7FvXgURnPFg90bmWkcdOt43lAO5JsKEsjcEsfnRZAbREfjZ8MFumyPEgbaTfoe/0FhKiXG23YNthdbl8cvTtSv2TCNyLa51eUMgf3x4fCAXdush7ktjWs/QBiaO1mOGYhGwh/O1ljsbtRc4vG+gvL93OgqjcIFvTPZli7a4+h6naRnWGBTVdWVQuQnY1Kaz58/9W+xbiXfB+KD/Te4MxkQ5KvqqSvJwMIkgVLiwd2OK/5gxzug7wM15WsrypTAe5kx7+gFyPWPfFt2djyVuWgtuKesCNFRTZpvxc/1j7l6rNx4gWBHQMXsQWVrBX5UYRTlgN0m5YSKf+j27Xh9dea6rwlopPTwmgJ+QGRESPVFh/v+qSR+Vf9qsLmlpa729sJ1qKxDjGVo5qZD5JagE4p5iUTNWDx+8m414z7kfvI/yRnMwCrn7voAAAAASUVORK5CYII=">
        <div class="font-family-list">
              <div class="font-family" (click)="params.fontFamily=font;styleChanged()" [style.font-family]="font" *ngFor="let font of fontFamilyStyles" [value]="font">
                {{font}}
                <img [hidden]="font!=params.fontFamily" class="check" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAACi0lEQVR4nO2YQWsTQRiGn9kmUKt48SaCF0U8CL1Z9NC0VjHR4slLo1fBn6Cg5Cd49iTU1kORItVUg4TQIojQW8VSvOhFETwpMaXZfB6aQlyTdZOd3cyu8952dnZnnieb3ZkPbGxsbGxsbGxs/s+oYU8g6uTzxcMNcWaU425Uy4ufvOedYUwqruQKc+MN1AeUPBVxtnKF4lVvn9QKyBXmxpU4r4Gj7aZRJeq2t18qBXTAH+lsF+Szt2/qBPSCB3lPpnXP238kpnnFkp7wSm01W8yslRe/ea9JzVfAF96V6fVXj790uy4VAgaFhxQICAMPCRcQFh4SLEAHPCRUgC54SKAAnfCQMAG64SFBAqKAh4QIiAoeEiAgSngwXEDU8GCwgDjgwVABccGDgQLihAfDBMQNDwYJGAY8GFIS84MXRqaiggefJ6BUKjlr7z7eF+GKgjcHM2N3V1Ye1nVPoHcNj82sqy5UKvN/lbF0pmdNUB04dhN4wF5ZeWLHbZ47c3piaXt7Y1fX4MOGB/+/wKnOA6Vk6sfur+ezs7fGdAxsAjz4CBBHPQEanW26JJgCDz4Cai/mNxVyjW4S3PrLXO76oUEG9H3hqczFOOEhwGdwOl+8JKhnwGhnuyjWqe8UarWln0EH+9fbvlZ+9DXovXQl0DpAhwQT4aGPhVAYCabCQ58rwUEkmAwPAyyF+5FgOjwMuBcIIiEJ8BBiM+QnQYncYe+c0fAQcjeYK9y4rIRlPBIA6XLv2Bc5QRJ6O9zrSfhzFPN++f1oqQf4SjAYHjQWRLpKMBweNBZEqqsLlfbe4Xu76W22yaTJ8KC5IlRdXahkXXVcWpycPHvivGkvPBsbGxsbGxsbGxsbm/38BmoO4CR4ZSfcAAAAAElFTkSuQmCC">
            </div>
        </div>
    </div>
    <div   [hidden]="checkProperty('stroke')" [tooltip]="'Stroke color'" (click)=" colorTypeChange('stroke')" class="btn-editor square-color" [style.background-color]="params.stroke"></div>
    <input [hidden]="checkProperty('strokeWidth')"  [tooltip]="'Stroke Width'" type="number" min="0" max="20" class="select font-size" (change)="styleChanged()" [(ngModel)]="params.strokeWidth">
    <input [hidden]="checkProperty('fontSize')" [tooltip]="'Font size'" class="text-number" (change)="styleChanged()" [(ngModel)]="params.fontSize"  type="number" />
    
    <div [hidden]="checkProperty('fill')" [tooltip]="'Color'"  (click)="colorTypeChange('fill')" class="btn-editor square-color" [style.background-color]="params.fill"></div>
    <div [hidden]="checkProperty('backgroundColor')" [tooltip]="'Background color'"  (click)="colorTypeChange('backgroundColor')" class="btn-editor square-color" [style.background-color]="params.backgroundColor"></div>
    <div [tooltip]="'clear background'" (click)="clearTextBackground()" class="btn-editor btn-clear"></div>
    <div [hidden]="checkProperty('fontWeight')" [tooltip]="'Bold'" [ngClass]="{'activeParams' : params.fontWeight==='bold'}" (click)="changeParams('fontWeight')" class="btn-editor text-bold"></div>
    <div [hidden]="checkProperty('fontStyle')" [tooltip]="'Italic'" [ngClass]="{'activeParams' : params.fontStyle==='italic'}" (click)="changeParams('fontStyle')" class="btn-editor text-italic"></div>
    <div [hidden]="checkProperty('underline')" [tooltip]="'Underline'" [ngClass]="{'activeParams' : params.underline}" (click)="changeParams('underline')" class="btn-editor text-underline"></div>
    <div [hidden]="checkProperty('list')" [tooltip]="'List'" [ngClass]="{'activeParams' : params.list}" (click)="params.list=!params.list;styleChanged()" class="btn-editor text-list"></div>
    <div [hidden]="checkProperty('textAlign')" [tooltip]="'Justify'" [ngClass]="{'activeParams' : params.textAlign==='justify'}" (click)="changeParams('textAlignJustify')"  class="btn-editor text-justify"></div>
    <div [hidden]="checkProperty('textAlign')" [tooltip]="'Left'" [ngClass]="{'activeParams' : params.textAlign==='left'}" (click)="changeParams('textAlignLeft')"  class="btn-editor text-left"></div>
    <div [hidden]="checkProperty('textAlign')" [tooltip]="'Center'" [ngClass]="{'activeParams' : params.textAlign==='center'}" (click)="changeParams('textAlignCenter')"  class="btn-editor text-center"></div>
    <div [hidden]="checkProperty('textAlign')" [tooltip]="'Right'" [ngClass]="{'activeParams' : params.textAlign==='right'}" (click)="changeParams('textAlignRight')"  class="btn-editor text-right"></div>
    <input [hidden]="checkProperty('lineHeight')" [tooltip]="'Line height'"  step='.1' class="text-number" (change)="styleChanged()" [(ngModel)]="params.lineHeight"  type="number" />

    <div [tooltip]="'Bring forward'" (click)="changeZIndex('bringToFront')" class="btn-editor text-forward"></div>
    <div [tooltip]="'Bring Back'" (click)="changeZIndex('sendToBack')" class="btn-editor text-back"></div>
    <div [tooltip]="'Top'" [ngClass]="{'activeParams' : params.vertical==='top'}" (click)="params.vertical='top';styleChanged()"  class="btn-editor align-top"></div>
    <div [tooltip]="'Middle'" [ngClass]="{'activeParams' : params.vertical==='middle'}" (click)="params.vertical='middle';styleChanged()"  class="btn-editor align-middle"></div>
    <div [tooltip]="'Bottom'" [ngClass]="{'activeParams' : params.vertical==='bottom'}" (click)="params.vertical='bottom';styleChanged()"  class="btn-editor align-bottom"></div>
    <div [tooltip]="'Left'" [ngClass]="{'activeParams' : params.horizontal==='left'}" (click)="params.horizontal='left';styleChanged()"  class="btn-editor align-left"></div>
    <div [tooltip]="'Right'" [ngClass]="{'activeParams' : params.horizontal==='right'}" (click)="params.horizontal='right';styleChanged()"  class="btn-editor align-right"></div>
    <div [tooltip]="'Flip horizontaly'" [ngClass]="{'activeParams' : params.flipX}" (click)="params.flipX=!params.flipX;styleChanged()"  class="btn-editor flipx"></div>
    <div [tooltip]="'Flip Verticaly'" [ngClass]="{'activeParams' : params.flipY}" (click)="params.flipY=!params.flipY;styleChanged()"  class="btn-editor flipy"></div>
    <input [hidden]="!opacityOpen" [(ngModel)]="params.opacity" (change)="styleChanged()" type="range" step='0.05' max='1' min='0'>
    <div [tooltip]="'Opacity'" class="btn-editor btn-opacity" (click)="toggleOpacity()"></div>   
    <div [hidden]="type!='image'" [tooltip]="'Use as background'" class="btn-editor btn-background" (click)="setAsBackGround()"></div>
    <div [tooltip]="'Lock'" class="btn-editor btn-locked" (click)="lock()"></div>

  <div [style.visibility]="!isMoreMenu? 'hidden': 'visible'"  class="card-more">
    <span class="arrow"></span>

    <div  class="more-submenu">
        <ul class="vertical-tabs-widget ">
           <li [ngClass]="{'selected' : moreMenu==='SPACING'}" (click)="moreMenu='SPACING'" class="selected ">Spacing & Opcacity</li>
           <li [ngClass]="{'selected' : moreMenu==='FILLBORDER'}" (click)="moreMenu='FILLBORDER'" class="">Fill Color</li>
           <li [ngClass]="{'selected' : moreMenu==='TRANSFORMATION'}" (click)="moreMenu='TRANSFORMATION'" class="">Transform</li>
           <li [ngClass]="{'selected' : moreMenu==='TEXTDIRECTION'}" (click)="moreMenu='TEXTDIRECTION'" class="">Text Direction</li>
        </ul>
     </div>

     <div [hidden]="moreMenu!='SPACING'" class="spacing">
        <table>
           
            <tr>
                <td>Border Spacing</td>
                <td><input [(ngModel)]="params.padding" (change)="styleChanged()" type="range"    step='1' max='50' min='1' ></td>
                <td>{{params.padding}}px</td>
            </tr>
            <tr>
                <td>Line Height</td>
                <td><input  [(ngModel)]="params.lineHeight" (change)="styleChanged()" type="range"    step='.1' max='5' min='1' ></td>
                <td>{{params.lineHeight*10}}px</td>
            </tr>
            <tr>
                <td>Letter spacing</td>
                <td><input [(ngModel)]="params.charSpacing" (change)="styleChanged()" type="range"    step='10' max='2000' min='0' ></td>
                <td>{{params.charSpacing/10}}px</td>
            </tr>
            <tr>
              <td colspan="3"><hr></td>    
            </tr>
            <tr>
                <td>Opacity</td>
                <td><input [(ngModel)]="params.opacity" (change)="styleChanged()" type="range"    step='0.05' max='1' min='0' ></td>
                <td>{{params.opacity*100}} %</td>
            </tr>
        </table>
     </div>

     <div [hidden]="moreMenu!='FILLBORDER'" class="border-fill">
       
     </div>


 <div [hidden]="moreMenu!='TRANSFORMATION'" class="transform">
        <a class="" (click)="transformation('lowercase')">default</a>
        <a class="" (click)="transformation('lowercase')">lower case</a>
        <a class="" (click)="transformation('uppercase')">UPPER CASE</a>
        <a class="" (click)="transformation('capitalize')">Capitalize</a></div>
  

  <div [hidden]="moreMenu!='TEXTDIRECTION'" class="text-direction">
    <a class="">Left to Right</a>
    <a class="selected">Right to Left</a>
</div>

</div>

    <div [hidden]="!colorType"  class="paletteColor">
        <img (click)=" closePalette()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAFHklEQVRoge2aX2xTdRTHP+cWNlYIT0AUNMEJA2OIJoKOxGBbkNGRPs7o/gRD2AQXfTOy+LLEBBIT30BUCBAom1GfNrcyMtqqUaNETSTRiYvgCyQMXxAK29p7fGhv6dq79t62Gzzwefr9zu93z/me9vfv/lp4yP1FquksFOry3p5KrDdFGgwxl5uIF8BAE6YaE4bqpcU13rHBwU8T1YpZcQKB4K4nlVQr0AQ8Dyws8cg08KOojBieVN/oUN/flcQvO4Gtze0vp0zpEVFfBX4UiKlpHIyPnBotx4HrwP6mXU9jpA4DL5UTsAhxA+0+Hznzu5uHPE479vb2GuJ9fD9ifgbUu5ZXmtWKdNWv2TB9efzid04fcvQN+HwtS6ir/VwgWL4+FwhDmph8NR7/4lbpriXwhV5bJklPBNhYFXEOUbjAglRzfLD/RrF+RrHGUKjLS9IYYJ7FAwhskqQn4vO1LCnWb9YEent7jf+SiS8F2Vx9eY7ZaNQt6qfISJl1EkvdYz0Cb8yJLHc0PLH2mcSV8d9sJ7ZtZpml8ldKb0rzxVTKkGe/GTr9R36D/RAyUh/x4IgHqPGYesiuoSCBQLBtO7BlziW5JxAIdgTyjQUJKPLu/Ohxj4rZk2+bMQcyB7O/8u0OSALXgZUO+18FVgALXMZR1QX18bMnr1iGGd+AkmzDvfhpMXkhFgmvEpF9gFmkr6nK3lgkvArDbCSduBtEJNmaa8gbQrLdpUOAiehI+BeA6PDpj0E7sU/CBO2Mnw1/AhAb6vsZKLrLzkJTbiWbQCjU5SV9nnfLSt+O9ux+EYucOW6ThAnamW5LE2ju2As8Uka8xs0tLXVWJZvA7anEespcOkU4Egi277PqsciZ4yLsSQtHRaQ7V7w/2LZbVQ+XEwuoWXSztsGqZBMwRRrs+ztCFA75g227LUN0OHwi/U3onvTQSpPuI0cpcQ4riod1VjG7Ckh6VagEA+RYINheG42Ej4A1nO5RFfGAIstzgmYQLXrqc4goHM4dThbVEg8gsNQqV+zMPoJooanQVg3uJaBS8u3HASoib+aOeYvocPhEzsSuLAjctMpGjvF6hX5NuwlrP7ErS0LQCaucTcBQvVSBT9ulMj3m5ViRJbY8UvxpFbMJLK7xjgFT5fhTZV+RpdJ2iRWR7rLEw+TdpZPZD3vGuccfbP8WeNGlw6uxSHjVPR+zrjYFu7E/2H4N97vx17FI2GdV8oOcc+kMYIV/Z+tzYB0PZl0qDZCjmSMEvuaOjcAy19FERmZUcyvbdrbWp0xjPN/ugCTpg5nTT/MasJxqH6czF60xl07JCHEzFB7FvXgURnPFg90bmWkcdOt43lAO5JsKEsjcEsfnRZAbREfjZ8MFumyPEgbaTfoe/0FhKiXG23YNthdbl8cvTtSv2TCNyLa51eUMgf3x4fCAXdush7ktjWs/QBiaO1mOGYhGwh/O1ljsbtRc4vG+gvL93OgqjcIFvTPZli7a4+h6naRnWGBTVdWVQuQnY1Kaz58/9W+xbiXfB+KD/Te4MxkQ5KvqqSvJwMIkgVLiwd2OK/5gxzug7wM15WsrypTAe5kx7+gFyPWPfFt2djyVuWgtuKesCNFRTZpvxc/1j7l6rNx4gWBHQMXsQWVrBX5UYRTlgN0m5YSKf+j27Xh9dea6rwlopPTwmgJ+QGRESPVFh/v+qSR+Vf9qsLmlpa729sJ1qKxDjGVo5qZD5JagE4p5iUTNWDx+8m414z7kfvI/yRnMwCrn7voAAAAASUVORK5CYII=">
        <color-sketch [color]="currentPaletteColor" class="colorSketch" (onChange)="handleChange($event)"></color-sketch>
    </div>
</div>

<div  class="slider">
    <input type='range' step='.1' max='1' min='.1' [(ngModel)]="zoom" (change)='setZoom()'>
    <span>{{zoom*100 | number:'2.0-0' }} %</span>
</div>