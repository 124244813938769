import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalServiceService } from './global-service.service';
import { ProjectValuesService } from './project-values.service';

@Injectable({
  providedIn: 'root'
})
export class CanvasService {

  public renderProjectSubject$: BehaviorSubject<boolean>;
  public renderProject: Observable<boolean>;
  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);



  constructor(
    private projectValuesSvc: ProjectValuesService,
    private gloablSvc: GlobalServiceService) {
    this.renderProjectSubject$ = new BehaviorSubject<any>(false);
    this.renderProject = this.renderProjectSubject$.asObservable();
  }

  CanvasToUrl(test,type) {


    let $this = this;
    let url:any;
    this.gloablSvc.canvas.requestRenderAll();
    this.gloablSvc.canvas.clone(function (canvas) {

      canvas.requestRenderAll();
      try {
        let zoom = 1;
        let quality = 1;
        switch (type) {

          case "THUMBNAIL": zoom = .2; break;
          case "LOW_PNG": zoom = 1; quality = .1; break;
        }
        let width = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).width;
        let height = $this.MENU_TYPES.get($this.projectValuesSvc.project.type).height;

        width = width * zoom;
        height = height * zoom;
        canvas.setZoom(zoom);
        canvas.setDimensions({
          width: width,
          height: height
        })
        canvas.renderAll();

         url = canvas.toDataURL({
          format: 'png',
          left: 0,
          top: 0,
          width: width,
          height: height
        });
        

      } catch (error) {
        console.error(error);
      }
    })
    
    return url;

  }


}
