import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit ,AfterViewInit, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';
import { AppModel } from '../model/app-model';
import { Header } from '../model/header';
import { User } from '../model/user';
import { ApiService } from '../services/api.service';
import { CanvasService } from '../services/canvas.service';
import { ExportService } from '../services/export.service';
import { GlobalServiceService } from '../services/global-service.service';
import { ProjectValuesService } from '../services/project-values.service';
import { ViewService } from '../services/view.service';
import { AppDataService } from './app-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  link:any;
  user:User;
  projectName:String;
  constructor(
    private viewSvc:ViewService,
    private router:Router,
    private apiService:ApiService,
    public projectSvc:ProjectValuesService) { 
    this.link=null;
  }
  
 



  ngOnInit(): void {



    this.apiService.currentUserSubject.subscribe((user:User)=>{
      this.user=user;
    });
    this.projectName = this.projectSvc.project.name;

  }

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  
 
 

 


  // prepare(){
  //   let base64String = "your-base64-string";
  //   this.downloadPdf(base64String,"sample");
  // }
  OpenLogin(){
    
    //this.canvasSvc.updateCanvasExport(`${this.canvasSvc.canvas.toDataURL({format: "png"})}`);
    this.viewSvc.updateView(this.viewSvc.LOGIN_REGITRATION);
  }

  SignOut(){
    localStorage.clear();
    this.apiService.currentUserSubject.next(null);
    this.apiService.currentJwtTokenSubject.next(null);
    this.router.navigate(['/new-project']);
    let map= new Map<any,any>();
    map.set("type","success")
    map.set("messages",["Sign Out Successful"])
    this.apiService.getApiErrors().next(map)
  }

  newProject(){

    localStorage.removeItem("project");
    this.projectSvc.clearProject();
    this.viewSvc.updateView(this.viewSvc.CREATE_NEW_PROJECT)
    this.router.navigate(['/new-project']);

  }



}
