import { AfterViewInit, Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { fabric } from 'fabric'
import { AppDataService } from './header/app-data.service';
import { AppModel } from './model/app-model';
import { CanvasService } from './services/canvas.service';
import { ConstantService } from './services/constant.service';
import { ElementServiceService } from './services/element-service.service';
import { GlobalServiceService } from './services/global-service.service';
import { ViewService } from './services/view.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {


  canvas: any;
  fontColor: boolean;
  typeForm: string;
  modalToggle:boolean;

   






  appliedStyle = {};
  // ["stroke","strokeWidth","fill","fontFamily","fontSize","fontWeight","fontStyle","underline","overline","linethrough","deltaY","textBackgroundColor"]




  constructor(
    private elementSvc: ElementServiceService,
    private canvasSvc:CanvasService,
    private viewSvc:ViewService,
    private appDataSvc:AppDataService,
    private constantSvc:ConstantService
  ) {


    this.fontColor = false;
    this.typeForm = null;
    this.modalToggle=true;

   
   
    



  }

  ngOnInit(): void {

   

   

     
   



    //this.elementSvc.getSubjectSharedForm().subscribe((type: string) => this.typeForm = type)
  }



  changeColor() {
    //this.elementSvc.changeColor();
  }

  ngAfterViewInit() {
   

  }

  onStyleChange() {
    this.elementSvc.applyStyle(this.appliedStyle)

  }

  /**
   * 
   * @param type in order to display form params for all type of shapes
   * @returns 
   */
  isShape(type:string){
    switch(type){
      case 'rect' :return 'shape'; break;
      case 'circle' :return 'shape'; break;
      case 'triangle' :return 'shape'; break;
      case 'line' :return 'shape'; break;
      default: return type;
    }
  }

  closeModal(){
    this.modalToggle=false;
  }

  


  disActivateAll(event:any){
   
  }



}