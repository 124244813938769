import { Injectable } from '@angular/core';
import { fabric } from 'fabric'
import { Subject } from 'rxjs';
import { GlobalServiceService } from './global-service.service';
import { ImageService } from './image.service';
import { ProjectValuesService } from './project-values.service';
import { ShapeService } from './shape.service';

@Injectable({
  providedIn: 'root'
})
export class ElementServiceService {

  public subjectSharedParams = new Subject();
  public subjectSharedForm = new Subject();
  public subjectLoadImage = new Subject();
  public resetAllElement = new Subject();
  public ActivObject = new Subject();

  protected param: any;
  public communBorderStyle: any;
  protected currentImageToCrop: any;
  protected deleteImageAfterCop: any;

  constructor(protected globalServce: GlobalServiceService, protected projectValuesSvc: ProjectValuesService) {
    this.communBorderStyle = {
      cornerColor: "#00ff00",
      cornerSize: 10,
      cornerStrokeColor: "#00ff00",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "#00ff00",
      editingBorderColor: "#00ff00"
    }
    this.param = {
      angle: 0,
      padding: 10,
      cornersize: 10,
      fill: '#2b3140',
      strokeWidth: 0,
      stroke: "#000",
      isLocked: false,
    }
  }

  get GlobalServiceService() { return this.GlobalServiceService }

  applyStyle(style: any) {

    let canvas = this.globalServce.canvas;
    var selectedObjects = canvas.getActiveObjects();
    let $this = this;

    selectedObjects.forEach(function(object) {
      if (object.hasOwnProperty("_objects")) {
        object._objects.forEach(function(subObject) { 
          subObject.set(style);
          subObject.set("dirty", true)
        });
      }
      else{
        object.set(style);
      }
      switch (style.horizontal) {
        case 'left': $this.process_align('left'); break;
        case 'right': $this.process_align('right'); break;
        case 'center': $this.process_align('center'); break;
      }
      switch (style.vertical) {
        case 'top': $this.process_align('top'); break;
        case 'bottom': $this.process_align('bottom'); break;
        case 'middle': $this.process_align('center'); if (style.horizontal != 'center') { $this.process_align(style.horizontal) }; break;
      }

      object.params = {...Object.assign({}, object.params, style)};
    
    });

   
    // active.set("dirty", true)
      canvas.requestRenderAll();
      canvas.renderAll();
  
return;
    var activeObject = canvas.getActiveObject();

    if (!activeObject) return;

    let actives = [];

    actives[0] = activeObject;

    if (activeObject.hasOwnProperty("_objects")) {
      actives = activeObject._objects;
    }
    for (var i = 0; i < actives.length; i++) {
      let active = actives[i];



      if (active.get("type") === "textbox") {
        if (active.getSelectedText().trim().length > 0) {
          active.setSelectionStyles(style)
        }
        else {
          active.params = style;
          active.set(style)
          //  this.globalServce.makeAsList()
        }
      }
      else {
        active.params = style;
        active.set(style)
      }

      // switch (style.horizontal) {
      //   case 'left': this.process_align('left'); break;
      //   case 'right': this.process_align('right'); break;
      //   case 'center': this.process_align('center'); break;
      // }
      // switch (style.vertical) {
      //   case 'top': this.process_align('top'); break;
      //   case 'bottom': this.process_align('bottom'); break;
      //   case 'middle': this.process_align('center'); if (style.horizontal != 'center') { this.process_align(style.horizontal) }; break;
      // }
      if (style.hasOwnProperty('clipPath')) {
        active.set('clipPath', new fabric.Circle({ radius: style.clipPath.radius, top: style.clipPath.top, left: style.clipPath.left }));
        active.set('dirty', true);
      }

      active.set("dirty", true)
      canvas.requestRenderAll();
      canvas.renderAll();
    }


  }

  /* Align the selected object */
  public process_align(val) {

    let canvas = this.globalServce.canvas;
    var activeObj = canvas.getActiveObject();
    // this.globalServce.canvas.centerObject(active)
    // canvas.requestRenderAll();
    // canvas.renderAll();


    canvas.width = this.projectValuesSvc.project.width;
    canvas.height = this.projectValuesSvc.project.height;
    // var activeObj = canvas.getActiveObject();
    switch (val) {

      case 'left':
        activeObj.set({
          left: 0
        });
        break;
      case 'right':
        activeObj.set({
          left: canvas.width - (activeObj.width * activeObj.scaleX)
        });
        break;
      case 'top':
        activeObj.set({
          top: 0
        });
        break;
      case 'bottom':
        activeObj.set({
          top: canvas.height - (activeObj.height * activeObj.scaleY)
        });
        break;
      case 'center':
        this.globalServce.canvas.centerObject(activeObj)
        break;
    }
    canvas.requestRenderAll();
    canvas.renderAll();
  }
  public changeZIndex(type: string) {
    let canvas = this.globalServce.canvas;
    var selectedObjects = canvas.getActiveObjects();

    
    selectedObjects.forEach(function(object) {
      if (object.hasOwnProperty("_objects")) {
        object._objects.forEach(function(subObject) { 
          switch (type) {
            case 'bringToFront': subObject.bringToFront(); break;
            case 'sendToBack': subObject.sendToBack(); break;
          }
          if(subObject.hasOwnProperty("path")){
            subObject.path.forEach(function(path) { 
              switch (type) {
                case 'bringToFront': path.bringToFront(); break;
                case 'sendToBack': path.sendToBack(); break;
              }
            })

          }
          subObject.set("dirty", true)
        });
      }
      else{
        switch (type) {
          case 'bringToFront': object.bringToFront(); break;
          case 'sendToBack': object.sendToBack(); break;
        }
      }
  })
  canvas.requestRenderAll();
  canvas.renderAll();

}

  addHoverBorder(obj: any) {
    obj.on('mouseover', function () {
      if (obj.params.isLocked) return;
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      })
    })
    obj.on('mousedown', function () {
      this.canvas.clearContext(this.canvas.contextTop);
    })
    obj.on('mouseout', function () {
      this.canvas.clearContext(this.canvas.contextTop);
    })
  }

  /**
   * Lock toogle , to lock the object from moving and being scaling.
   */
  lockToggle(type: string) {
    let canvas = this.globalServce.canvas;
    var active = canvas.getActiveObject();
    active.params.isLocked = !active.params.isLocked;
    if (active.params.isLocked) {
      active.params.hasControls = false;
      active.params.lockMovementX = true;
      active.params.lockMovementY = true;

    } else {
      active.params.hasControls = true;
      active.params.lockMovementX = false;
      active.params.lockMovementY = false;

    }
    active.set(active.params);
    canvas.requestRenderAll();
    this.updateSubjectSharedParams(new Map([[type, { ...active.params }]]));
  }

  public startCrop() {

    let canvas = this.globalServce.canvas;
    var active = canvas.getActiveObject();
    this.currentImageToCrop = { ...active }

    let params = {
      fill: "rgba(0,0,0,0.0)",
      originX: "left",
      originY: "top",
      opacity: 1,
      width: 200,
      height: 100,
      hasRotatingPoint: false,
      transparentCorners: false,
      cornerColor: "pink",
      cornerSize: 10,
      cornerStrokeColor: "pink",
      cornerStyle: "circle",
      borderDashArray: [3],
      borderColor: "pink",
      editingBorderColor: "pink",
      crop: true,
    }
    let shape = new fabric.Rect({ ...params });
    shape.set('type', 'crop');
    shape.params = { ...params };
    // get active image to be deleted after the crop
    shape.image = active;
    this.addHoverBorder(shape);
    this.globalServce.canvas.add(shape);
    this.globalServce.canvas.centerObject(shape)
    this.globalServce.canvas.setActiveObject(shape);
    this.updateSubjectSharedForm("crop");


  }

  public applyCrop() {

    let canvas = this.globalServce.canvas;
    var selectionRect = canvas.getActiveObject();

    let rect = new fabric.Rect({
      left: selectionRect.left,
      top: selectionRect.top,
      width: selectionRect.getScaledWidth(),
      height: selectionRect.getScaledHeight(),
      absolutePositioned: true,

    });
    this.currentImageToCrop.clipPath = rect;



    let src = canvas.toDataURL({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    });

    // get active image to be deleted after the crop
    canvas.remove(selectionRect.image);
    canvas.remove(selectionRect);
    let $this = this;
    fabric.Image.fromURL(src, function (image) {

      image.set($this.param);
      image.params = $this.param;
      image.scaleToWidth(200);
      image.set($this.communBorderStyle)
      canvas.add(image);
      canvas.centerObject(image)
    });

    this.updateResetAllElements();
    canvas.renderAll();



  }
  cancelCrop() {

    let canvas = this.globalServce.canvas;;

    for (var i = 0; i < canvas.getObjects().length; ++i) {
      if (canvas.item(i).type === "crop") {
        canvas.remove(canvas.item(i)); break;
      }
    }
    this.updateResetAllElements();
    canvas.renderAll();
  }

  duplicate() {

    let canvas = this.globalServce.canvas;
    var activeObjects = canvas.getActiveObjects();
    let $this = this;

    if (activeObjects) {

      activeObjects.forEach(function (object) {

        let params = object.params;
        object.clone(function (cloned) {
          cloned.params = { ...params };
          cloned.params.hasControls = true,
            cloned.params.lockMovementX = false,
            cloned.params.lockMovementY = false,
            cloned.set($this.communBorderStyle);
          canvas.add(cloned.set({
            left: object.left + 400,
            top: object.top + 500
          }));
        })

      });
    }

  }


  delete() {

    let canvas = this.globalServce.canvas;
    var activeObject = canvas.getActiveObject();

    if (!activeObject) return;

    let actives = [];

    actives[0] = activeObject;

    if (activeObject.hasOwnProperty("_objects")) {
      actives = activeObject._objects;
    }
    for (var i = 0; i < actives.length; i++) {
      canvas.remove(actives[i])
      canvas.discardActiveObject().renderAll();
    }
    canvas.remove(activeObject)
    canvas.discardActiveObject().renderAll();
    this.updateActiveObject(null);
  }

  setCanvasBackGround(color: any) {
    let canvas = this.globalServce.canvas;
    canvas.backgroundColor = color;
    canvas.renderAll();
  }

  public updateSubjectSharedParams(params: any) {
    this.subjectSharedParams.next(params);
  }
  public updateSubjectSharedForm(type: string) {
    this.subjectSharedForm.next(type);
  }
  public updateSubjectLoadImageForCrop(type: string) {
    this.subjectLoadImage.next(type);
  }
  public updateResetAllElements() {
    let isReset: Boolean = true;
    this.resetAllElement.next(isReset);
    this.updateActiveObject(null)
  }
  public updateActiveObject(active: any) {
    this.ActivObject.next(active);
  }

  public getSubjectSharedParams() { return this.subjectSharedParams; }
  public getSubjectSharedForm() { return this.subjectSharedForm; }
  public getubjectLoadImageForCrop() { return this.subjectLoadImage; }
  public getResetAllElements() { return this.resetAllElement; }
  public getActiveObject() { return this.ActivObject; }



}
