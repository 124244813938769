import { JsonPipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Project } from '../model/project';
import { User } from '../model/user';
import { ViewService } from './view.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiErrors$ = new Subject<any>();
  private  HOST="https://imakemenu-e0296cc29af3.herokuapp.com";

  public currentUserSubject :BehaviorSubject<any>;
  public currentUser :Observable<any>;


  public currentJwtTokenSubject: BehaviorSubject<any>;
  public currentJwtToken: Observable<any>;

  public allProjectsSubject: BehaviorSubject<any>;
  public projects: Observable<any>;

  public projectSubject: BehaviorSubject<any>;
  public currentProject: Observable<any>;





  constructor(private http: HttpClient,private viewSvc:ViewService) {


    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentJwtTokenSubject = new BehaviorSubject<any>(localStorage.getItem('token'));
    this.currentJwtToken = this.currentJwtTokenSubject.asObservable();

    this.projectSubject = new BehaviorSubject<any>(localStorage.getItem('project'));
    this.currentProject = this.projectSubject.asObservable();

    this.allProjectsSubject = new BehaviorSubject<any>([]);
    this.projects = this.allProjectsSubject.asObservable();
   }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  userResgiter(register: any): Observable<any> {
    return this.http.post<any>(this.HOST+"/register", JSON.stringify(register), this.httpOptions)
    .pipe(
      map(response => {
       
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        this.currentUserSubject.next(response.data.user);
        this.currentJwtTokenSubject.next(response.data.token);

        let map= new Map<any,any>();
        map.set("type","success")
        map.set("messages",[response.message])
        this.apiErrors$.next(map)
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  userLogin(login: any): Observable<any> {
    return this.http.post<any>(this.HOST+"/login", JSON.stringify(login), this.httpOptions)
    .pipe(
      map(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
       
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        this.currentUserSubject.next(response.data.user);
        this.currentJwtTokenSubject.next(response.data.token);

        let map= new Map<any,any>();
        map.set("type","success")
        map.set("messages",[response.message])
        this.apiErrors$.next(map)
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }


  refreshUserToken(): Observable<any> {
    return this.http.get<any>(this.HOST+"/api/refresh-token", this.httpOptions)
    .pipe(
      map(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
       
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        this.currentUserSubject.next(response.data.user);
        this.currentJwtTokenSubject.next(response.data.token);

        let map= new Map<any,any>();
        map.set("type","success")
        map.set("messages",[response.message])
        this.apiErrors$.next(map)
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  FetchProjects(): Observable<any> {
    return this.http.get<any>(this.HOST+"/api/all-project", this.httpOptions)
    .pipe(
      map(response => {
        return response.data;
        
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }


  FetchProjectDetails(projectId:String): Observable<any> {
    return this.http.get<any>(this.HOST+"/api/project?projectId="+projectId,this.httpOptions)
    .pipe(
      map(response => {
        return response.data;
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  DeleteProject(projectId:String): Observable<any> {
    return this.http.delete<any>(this.HOST+"/api/project?projectId="+projectId,this.httpOptions)
    .pipe(
      map(response => {
        return response.data;
        this.allProjectsSubject.next(response.data.map(e=>{return {name:e.name,projectId:e.projectId}}));
       // return response.data.map(e=>{return {name:e.name,projectId:e.projectId}});
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  FetchAllTemplates(): Observable<any> {
    return this.http.get<any>(this.HOST+"/api/all-templates",this.httpOptions)
    .pipe(
      map(response => {
        return response.data;
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  useAsTemplate(templateId:any,projectId:any): Observable<any> {
    return this.http.get<any>(this.HOST+"/api/use-template?templateId="+templateId+"&projectId="+projectId,this.httpOptions)
    .pipe(
      map(response => {
        return response.data;
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  SyncProject(project: Project): Observable<any> {
    project.data = JSON.stringify(project.data)
    return this.http.post<any>(this.HOST+"/api/synch-project", project, this.httpOptions)
    .pipe(
      map(response => {


        let map= new Map<any,any>();
        map.set("type","success")
        map.set("messages",["saved"])
        this.apiErrors$.next(map);
        return response;
      }
    ),
      catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              this.getServerErrorMessage(error);
          }
          return throwError(errorMsg);
      })
  );
  }

  private getServerErrorMessage(error: HttpErrorResponse){
    switch (error.status) {
        case 400: {
          let map= new Map<any,any>();
          map.set("type","error")
          map.set("messages",error.error.message.errors)
          this.apiErrors$.next(map); break;
        }
        case 403: {
          let map= new Map<any,any>();
          map.set("type","error")
          map.set("messages",error.error.message.errors)
          this.apiErrors$.next(map);
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          this.currentUserSubject.next(null);
          break;
        }
      }
    }

  
  getApiErrors(){
      return this.apiErrors$;
  }

  public get currentUserValue() : User {
    return this.currentUserSubject.value;
  }

  public get currentJwtTokenValue() {
    return this.currentJwtTokenSubject.value;
  }



}
