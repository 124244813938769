import {Component, OnInit } from '@angular/core';
import { Project } from '../model/project';
import { ApiService } from '../services/api.service';
import { fabric } from 'fabric'
import { ProjectValuesService } from '../services/project-values.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-new-canvas',
  templateUrl: './new-canvas.component.html',
  styleUrls: ['./new-canvas.component.scss']
})
export class NewCanvasComponent  implements OnInit {


  private MENU_TYPES = new Map<any, any>([
    ['LETTER', { width: 816, height: 1058 }],
    ['LEGAL', { width: 816, height: 1342 }],
    ['TABLOID', { width: 1058, height: 1633 }]
  ]);
  menuTypes:Map<any,any>;
  selectedMenu:String;

  public project: Project = { data: null, type: null, name: null, projectId: null };
  displayAlreadyHaveAccount: boolean;
  projectForm:FormGroup;
  constructor(
    public projectValuesSvc: ProjectValuesService,
    private apiService: ApiService) {}



  ngOnInit(): void {

    this.projectForm = new FormGroup({
      projectName: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
    });
    this.menuTypes= new Map<any,any>();
    this.menuTypes.set("LETTER",'8.5" x 11" |	21.6cm x 28cm	| 816 x 1058 px')
    this.menuTypes.set("LEGAL",'8.5" x 14"	| 21.6cm x 35.5cm	| 816 x 1342 px')
    this.menuTypes.set("TABLOID",'11" x 17"	| 28cm x 43.2cm	| 1058 x 1633 px')
    this.selectedMenu="LETTER";
  }

  submite(projectForm: FormGroup){
    if(projectForm.valid){
      let canvas = new fabric.Canvas('myCanvas');
      let project = {
        "projectId": null,
        "data": canvas.toJSON(["params", "id"]),
        "lowPng":" ",
        "thumbnail": " ",
        "type":  this.selectedMenu,
        "name": projectForm.controls.projectName.value
      }
      this.apiService.SyncProject(project ).subscribe((response: any) => {
        window.location.replace("/dashboard/"+response.data.projectId);
      });

    }
  }
}
