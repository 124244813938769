import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ViewService {


  private OpenView = new Subject();

  public  LOGIN_REGITRATION ='LOGIN_REGITRATION';
  public  CREATE_NEW_PROJECT ='CREATE_NEW_PROJECT';

  constructor() { }

  public getView(){
    return this.OpenView;
  }
  public updateView(view:string){
    this.OpenView.next(view);
  }

}
