import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Project } from '../model/project';
import { User } from '../model/user';
import { ApiService } from '../services/api.service';
import { CanvasService } from '../services/canvas.service';
import { ProjectValuesService } from '../services/project-values.service';
import { ProjectService } from '../services/project.service';
import { ViewService } from '../services/view.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {

  projectForm:FormGroup;
  menuTypes:Map<any,any>;
  selectedMenu:String;

  private MENU_TYPES =  new Map<any,any>([
    ['LETTER', {width: 816,height:1058}],
    ['LEGAL', {width: 816,height:1342}],
    ['TABLOID', {width: 1058,height:1633}]
  ])
  displayAlreadyHaveAccount: boolean;

  constructor(
    private apiService:ApiService,
    private canvasSvc:CanvasService,
    private viewSvc:ViewService,
    private projectSvc:ProjectService,
    private projectValuesSvc: ProjectValuesService) {
      this.displayAlreadyHaveAccount=false;
     }

  ngOnInit(): void {
    this.projectForm = new FormGroup({
      projectName: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(30)]),
    });
    this.menuTypes= new Map<any,any>();
    this.menuTypes.set("LETTER",'8.5" x 11" |	21.6cm x 28cm	| 816 x 1058 px')
    this.menuTypes.set("LEGAL",'8.5" x 14"	| 21.6cm x 35.5cm	| 816 x 1342 px')
    this.menuTypes.set("TABLOID",'11" x 17"	| 28cm x 43.2cm	| 1058 x 1633 px')
    this.selectedMenu="LETTER";



   
    this.apiService.currentUserSubject.subscribe((user:User) =>{   
      if(user===null)  {
        this.displayAlreadyHaveAccount=true;
      }
    })
    
  }
  closeModal(){
    this.viewSvc.updateView(null);
  }
  submite(projectForm: FormGroup){
    if(projectForm.valid){
      let project:Project={}
      project.name = projectForm.controls.projectName.value;
      project.type = this.selectedMenu;
      project.width = this.MENU_TYPES.get(this.selectedMenu).width;
      project.height = this.MENU_TYPES.get(this.selectedMenu).height;
      project.type = this.selectedMenu;

      this.projectValuesSvc.project = {...project};
      
      localStorage.setItem("project",JSON.stringify(project));
      this.canvasSvc.renderProjectSubject$.next(true);
      this.viewSvc.updateView(null)
      this.closeModal();
    }
  }

  signin(){
    this.viewSvc.updateView(this.viewSvc.LOGIN_REGITRATION);
  }

  
}

